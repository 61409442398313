/* eslint-disable no-magic-numbers */
function mountVideoThumbCanvas(src, currentTime) {
	return new Promise((resolve) => {
		const video = document.createElement('video')
		video.crossOrigin = 'anonymous'
		video.setAttribute('muted', true)

		video.addEventListener('loadedmetadata', loadedmetadata)
		video.addEventListener('seeked', onseeked)
		video.src = src
		video.load()

		function loadedmetadata() {
			this.currentTime = currentTime
			return this.currentTime
		}

		function onseeked() {
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			canvas.height = video.videoHeight * 0.5
			canvas.width = video.videoWidth * 0.5
			ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
			const url = canvas.toDataURL()
			canvas.toBlob((blob) => {
				resolve({
					url,
					blob,
				})
			})

			video.removeEventListener('loadedmetadata', loadedmetadata)
			video.removeEventListener('seeked', onseeked)
		}
	})
}

export default mountVideoThumbCanvas
