import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
}

const footageCategories = createSlice({
	name: 'footageCategories',
	initialState,
	reducers: {
		fetched(state, { payload: data }) {
			if (Array.isArray(data)) {
				state.data = data
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		loaded(state, { payload: isLoaded }) {
			state.loaded = isLoaded
		},
	},
})

export const Creators = footageCategories.actions

export const { fetched, fetching } = footageCategories.actions

export default footageCategories.reducer
