import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: {},
	items: [],
	selectedItems: [],
	sortBy: 'created_at',
	order: 'desc',
	perPage: 25,
	currentPage: 1,
	totalOfPages: 1,
	totalResults: 0,
	loaded: false,
	fetching: false,
	deleting: false,
	deleted: false,
	currentAsset : []
}

const singleLink = createSlice({
	name: 'singleLink',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
			}
		},

		fetchedItems(state, { payload }) {
			if (payload.data) {
				state.items = payload.data
				state.loaded = true
				state.fetching = false
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		deleting(state) {
			state.deleting = true
			state.deleted = false
		},

		deleted(state) {
			state.deleting = false
			state.deleted = true
		},

		clear(state) {
			state.currentPage = 1
			state.totalOfPages = 1
			state.loaded = false
			state.fetching = false
			state.deleting = false
			state.deleted = false
		},

		setCurrentPage(state, { payload }) {
			state.currentPage = payload
		},

		setPerPage(state, { payload }) {
			state.perPage = payload
		},

		setSortBy(state, { payload }) {
			state.sortBy = payload
		},

		setOrder(state, { payload }) {
			state.order = payload
		},

		setMyLinks(state, { payload }) {
			state.myLinks = payload
		},

		addSelected(state, { payload: link }) {
			state.selectedItems = [
				...state.selectedItems,
				link,
			].removeDuplicatedObjectsByKey({
				selector: 'id',
			})
		},
		removeSelected(state, { payload: link }) {
			state.selectedItems = state.selectedItems.filter(
				(item) => item.id !== link.id,
			)
		},
		deselectAll(state) {
			state.selectedItems = []
		},

		setCurrentAsset(state, { payload }) {
			state.currentAsset = payload
		}
	},
})

export const Creators = singleLink.actions

export default singleLink.reducer
