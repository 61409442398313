import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
	creating: false,
	created: false,
}

const mostSelectedTags = createSlice({
	name: 'mostSelectedTags',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			state.data = payload
			state.loaded = true
			state.fetching = false
		},

		fetching(state, { payload }) {
			state.fetiching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},
	},
})

export const Creators = mostSelectedTags.actions

export default mostSelectedTags.reducer
