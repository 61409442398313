import { Suspense, lazy } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

// polys
import '.helpers/polyfills'

// store
import { Provider } from 'react-redux'
import store from '.store/index'

// components
import WaitingRoom from '.components/WaitingRoom'

const LoadStyles = lazy(() => import('./LoadStyles'))
const Toast = lazy(() => import('.components/Toast'))
const Routes = lazy(() => import('.routes/index'))

function App() {

	if (process.env.NODE_ENV === 'development') {
		console.log(process.env)
	}

	return (
		<Provider store={store}>
			<Suspense fallback={<WaitingRoom size={150} />}>
				<LoadStyles />
				{window?.location?.host === 'scf-stage.readyset.co' ? (
					<>
						<div className='stageBar'>Stagging at {window?.location?.host}</div>
					</>
				) : null}
				<Router basename={process.env.REACT_APP_BASENAME || ''}>
					<Routes />
				</Router>
				<Toast />
			</Suspense>
		</Provider>
	)
}

export default App
