import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	uploadingFiles: {},
}

const highResolutionUploads = createSlice({
	name: 'highResolutionUploads',
	initialState,
	reducers: {
		setUploadingFiles(state, { payload: uploads }) {
			state.uploadingFiles = uploads
		},

		addUploadingFile(state, { payload: fileData }) {
			if (!state.uploadingFiles[fileData.type]) {
				state.uploadingFiles[fileData.type] = {}
			}

			const libItems = state.uploadingFiles[fileData.type]
			if (!libItems[fileData.libItemId]) {
				libItems[fileData.libItemId] = []
			}

			if (
				!libItems[fileData.libItemId].find(
					(item) => item.path === fileData.file.path,
				)
			) {
				libItems[fileData.libItemId].push(fileData.file)
			}
		},
	},
})

export const Creators = highResolutionUploads.actions
export const { addUploadingFile } = highResolutionUploads.actions

export default highResolutionUploads.reducer
