import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	customCategories: [],
	loaded: false,
	fetching: false,
}

const libAdCustomCategories = createSlice({
	name: 'libAdCustomCategories',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			state.data = payload || []
			state.loaded = true
			state.fetching = false
		},

		setCustomCategories(state, { payload: categories }) {
			if (Array.isArray(categories)) {
				state.customCategories = categories
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const Creators = libAdCustomCategories.actions

export default libAdCustomCategories.reducer
