import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	loaded: false,
	fetching: false,
	bkp: {}
}

const libImagesSingles = createSlice({
	name: 'LIB/IMAGES/SINGLE',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.id) {
				state.data = payload
				state.loaded = true
				state.fetching = false
				state.updating = false
			}
		},

		fetching(state) {
			state.loaded = false
			state.fetching = true
			state.updating = false
		},
		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
		clear(state) {
			Object.assign(state, initialState)
		},
		setIsClientProvided(state, { payload: isClientProvided }) {
			if (state?.data?.id) {
				state.data.client_provided = isClientProvided
			}
		},
		setCompanyId(state, { payload: companyId }) {
			state.bkp.company_id = state?.data?.company_id
			state.data.company_id = companyId
		},

		resetCompanyId(state) {
			state.data.company_id = state.bkp.company_id
		},

		setIsBranded(state) {
			state.data.branded = !state.data?.branded
		}
	},
})

export const Creators = libImagesSingles.actions

export const { fetched, fetching, clear, setIsClientProvided } = libImagesSingles.actions

export default libImagesSingles.reducer
