import { createSlice } from '@reduxjs/toolkit'

import { reducers } from '.reducers/libraries/bulk.template'

import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'

const initialState = {
	selected: [],
	showOkButton: false,
	showCompanySelector: false,
}

const musicBulk = createSlice({
	name: 'LIB/MUSIC/BULK',
	initialState,
	reducers: {
		...reducers,
		addSelected(state, { payload: libItem }) {
			state.selected = [
				...state.selected,
				{ ...libItem, images: { full: libItem?.thumbnail?.preview_url } },
			]
		},

		addMultipleSelected(state, { payload: libArr }) {
			state.selected = removeArrayDupsByKey([...state.selected, ...libArr], {
				selector: 'id',
			}).map((entity) => ({
				...entity,
				images: { full: entity?.thumbnail?.preview_url },
			}))
		},
	},
})

export const {
	addSelected,
	removeSelected,
	addMultipleSelected,
	removeMultipleSelected,
	clearSelected,
	setShowOkButton,
	setShowCompanySelector,
	onBulkTagsUpdated,
	setSelected,
	updateSelected
} = musicBulk.actions

export default musicBulk.reducer
