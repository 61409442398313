const BDS_AMOUNT = 10
const DS_AMOUNT = 300
const TVS_AMOUNT = 10

const padLength = 2

const bds = [...new Array(BDS_AMOUNT)].map(
	(_, i) => `bd${`${i + 1}`.padStart(padLength, '0')}`,
)
const ds = [...new Array(DS_AMOUNT)].map(
	(_, i) => `d${`${i + 1}`.padStart(padLength, '0')}`,
)
const tvs = [...new Array(TVS_AMOUNT)].map(
	(_, i) => `tv${`${i + 1}`.padStart(padLength, '0')}`,
)

const deliveryNumberOpts = ['bd', ...bds, ...ds, ...tvs].map((dNumber) => ({
	label: dNumber.toUpperCase(),
	value: dNumber,
	id: dNumber,
}))

export default deliveryNumberOpts
