import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const urlQueryStore = createSlice({
	name: 'URL_PARAMS',
	initialState,
	reducers: {
		setAllQueryParams(state, { payload: query }) {
			for (const key in query) {
				state[key] = query[key]
			}
		},
		setClear() {
			return initialState
		},
	},
})

export const { setAllQueryParams, setClear } = urlQueryStore.actions

export default urlQueryStore.reducer
