/* eslint-disable no-magic-numbers */
function handlePagination({ page, size = 0, step = 2, dots = '...' }) {
	const pages = []

	function add(first, sz) {
		for (let pg = first; pg < sz; pg++) {
			pages.push(pg)
		}
	}

	function addFirst() {
		pages.push(1)
		pages.push(dots)
	}

	function addLast() {
		pages.push(dots)
		pages.push(size)
	}

	if (size < step * 2 + 6) {
		add(1, size + 1)
	} else if (page < step * 2 + 1) {
		add(1, step * 2 + 4)
		addLast()
	} else if (page > size - step * 2) {
		addFirst()
		add(size - step * 2 - 2, size + 1)
	} else {
		addFirst()
		add(page - step, page + step + 1)
		addLast()
	}

	return pages
}

export default handlePagination
