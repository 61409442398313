export { default as throttle } from './throttle'
export { default as debounce } from './debounce'
export { default as deliveryNumberOpts } from './deliveryNumbers'
export { default as stringShortner } from './stringShortner'
export { default as sleep } from './sleep'
export { default as classNames } from './classNames'
export { default as secondsToMMSS } from './secondsToMMSS'
export { default as camelize } from './camelize'
export { default as capitalize } from './capitalize'
export { default as generateKeys } from './generateKeys'
export { default as removeEmptyProps } from './removeEmptyProps'
export { default as updateLocation } from './updateLocation'
export { default as handlePagination } from './handlePagination'
export { default as dayjs } from './dayjs'
export { default as removeArrayDupsByKey } from './removeArrayDupsByKey'
export { default as mountTagOption } from './mountTagOption'
export { default as formatDecimal } from './formatDecimal'
export { default as isObjEmpty } from './isObjEmpty'
export { default as isObject } from './isObject'
export { default as mountVideoThumbCanvas } from './mountVideoThumbCanvas'

// export { default as artificialDownload } from './artificialDownload'
// export { default as getCustomMetricVars } from './getCustomMetricVars'
// export { default as getAggrFieldName } from './getAggrFieldName'
// export { default as slugify } from './slugify'
// export { default as updateQueryParams } from './updateQueryParams'
// export { default as snakeToCamel } from './snakeToCamel'
// export { default as isOdd } from './isOdd'
// export { default as memoize } from './memoize'
// export { default as encodeMetricFormula } from './encodeMetricFormula'


// ??????? //
export default (obj, path = '') => {
	const crumbs = path.split('.')
	let value = obj

	for (const crumb of crumbs) {
		if (value.hasOwnProperty(crumb)) {
			value = value[crumb]
		}
	}

	return value
}

/**
 *
 * @param {number} srcWidth
 * @param {number} srcHeight
 * @param {number} maxWidth
 * @param {number} maxHeight
 * @returns {object} { width, height }
 */
export function calculateAspectRatio(srcWidth, srcHeight, maxWidth, maxHeight) {
	const [width, height] = [maxWidth / srcWidth, maxHeight / srcHeight]
	const ratio = Math.min(width, height)

	return { width: srcWidth * ratio, height: srcHeight * ratio }
}
