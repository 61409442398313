import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	selected: [],
	myLinks: true,
	searchTerm: '',
	currentPage: 1,
	totalOfPages: 1,
	totalResults: 0,
	loaded: false,
	fetching: false,
	creating: false,
	created: false,
	createdId: null,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	searchHistory: [],
}

const links = createSlice({
	name: 'links',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		search(state, { payload }) {
			state.search = payload
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		creating(state) {
			state.creating = true
		},

		created(state, { payload }) {
			state.creating = false
			state.created = true
			state.createdId = payload
		},

		deleting(state) {
			state.deleting = true
			state.deleted = false
		},

		deleted(state) {
			state.deleting = false
			state.deleted = true
		},

		updating(state) {
			state.updating = true
			state.updated = false
		},

		updated(state) {
			state.updating = false
			state.updated = true
		},

		clear(state) {
			state.currentPage = 1
			state.totalOfPages = 1
			state.createdId = null
			state.loaded = false
			state.fetching = false
			state.creating = false
			state.created = false
			state.deleting = false
			state.deleted = false
		},

		clearCreatedId(state) {
			state.createdId = null
		},

		setCurrentPage(state, { payload }) {
			state.currentPage = payload
		},

		setSearchTerm(state, { payload }) {
			state.searchTerm = payload
		},

		setMyLinks(state, { payload }) {
			state.myLinks = payload
		},

		addSelected(state, { payload: link }) {
			state.selected = [...state.selected, link].removeDuplicatedObjectsByKey({
				selector: 'id',
			})
		},
		removeSelected(state, { payload: link }) {
			state.selected = state.selected.filter((item) => item.id !== link.id)
		},
		deselectAll(state) {
			state.selected = []
		},

		addToSearchHistory(state, { payload: item }) {
			if (item && !state.searchHistory.some(({ term }) => term === item)) {
				// eslint-disable-next-line no-magic-numbers
				if (state.searchHistory.length >= 10) state.searchHistory.splice(0, 1)
				if (item) state.searchHistory.push(item)
			}
		},
	},
})

export const Creators = links.actions

export default links.reducer
