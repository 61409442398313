import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/library.template'

const initialState = {
	...initialStateTemplate,
	data: [],
	dataBkp: [],
	creating: false,
	created: false,
	loaded: false,
	fetching: false,
	filters: [],
	favorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	actors: [],
	locations: [],
	updating_actor: false,
	isPlaying: null,
}

const music = createSlice({
	name: 'LIB/MUSIC',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		updateMusic(state, { payload: musicUpdated }) {
			if (isObject(musicUpdated)) {
				const foundIndex = state.data.findIndex(
					({ id }) => id === musicUpdated.id,
				)
				state.data.splice(foundIndex, 1, {
					...musicUpdated,
					companies: musicUpdated?.companies[0]?.id
						? musicUpdated?.companies.map((e) => e.id)
						: musicUpdated?.companies,
				})
			}
		},

		setFavorites(state, { payload: favorites }) {
			state.favorites = favorites
		},

		removeMusic(state, { payload: imageId }) {
			state.data = state.data.filter(({ id }) => id !== imageId)
		},

		clearMusicBkp(state) {
			state.dataBkp = []
		},

		createMusicBkp(state) {
			state.dataBkp = [...state.data]
		},

		restoreMusicBkp(state) {
			state.data = [...state.dataBkp]
		},

		setIsPlaying(state, { payload: isPlaying }) {
			state.isPlaying = isPlaying
		},
	},
})

export const Creators = music.actions

export const {
	fetched,
	fetching,
	updateMusic,
	setFavorites,
	setAllFavorites,
	clearMusicBkp,
	removeMusic,
	createMusicBkp,
	restoreMusicBkp,
	setIsPlaying,
	clearBkp,
	createAssetsBkp,
	updateAsset,
	restoreBkp,
} = music.actions

export default music.reducer
