import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	footage: null,
	loaded: false,
	fetching: false,
	updating_actor: false,
	keyboard_blocked: false,
}

const singleSelect = createSlice({
	name: 'singleSelect',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.select?.id) {
				state.data = payload.select
				state.footage = payload.footage
				state.loaded = true
				state.fetching = false
				state.updating = false
			}
		},

		fetching(state) {
			state.loaded = false
			state.fetching = true
			state.updating = false
		},

		updating_actor(state) {
			state.updating_actor = true
		},

		updated_actor(state) {
			state.updating_actor = false
		},

		updateTags(state, { payload: tags }) {
			state.data.tags = tags
		},

		blockKeyboard(state) {
			state.keyboard_blocked = true
		},

		unlockKeyboard(state) {
			state.keyboard_blocked = false
		},

		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
		clear(state) {
			state.data = false
			state.loaded = false
			state.loading = false
		},
		setIsBranded(state) {
			state.data.branded = !state.data.branded
		},
		setBusinessUnit(state, { payload: businessUnit }) {
			state.data.business_unit = businessUnit
		},

		updateAttribute(state, { payload: property }) {
			if (property.attribute) {
				state.data[property.attribute] = property.value
			}
		},
	},
})

export const Creators = singleSelect.actions

export const { fetched, fetching, clear, updateTags } = singleSelect.actions

export default singleSelect.reducer
