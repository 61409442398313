import { createSlice } from '@reduxjs/toolkit'

import { reducers } from '.reducers/libraries/bulk.template'

import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'

const initialState = {
	selected: [],
	showOkButton: false,
	showCompanySelector: false,
}

const footageBulks = createSlice({
	name: 'LIB/TALENTS/BULK',
	initialState,
	reducers: {
		...reducers,
		addMultipleSelected(state, { payload: libArr }) {
			state.selected = removeArrayDupsByKey([...state.selected, ...libArr], {
				selector: 'id',
			}).map((talent) => ({
				...talent,
				images: { full: talent?.gallery[0]?.thumbnail_original },
				video: talent?.gallery[0]?.video?.url,
			}))
		},
	},
})

export const {
	addSelected,
	setSelected,
	removeSelected,
	addMultipleSelected,
	removeMultipleSelected,
	clearSelected,
	setShowOkButton,
	setShowCompanySelector,
	onBulkTagsUpdated,
} = footageBulks.actions

export default footageBulks.reducer
