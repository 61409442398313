function mapRenamedAssets(store) {
	return {
		...store,
		renamedAssets: store.assets,
	}
}

function replaceStringBy(store) {
	return {
		...store,
		renamedAssets: store.renamedAssets.map((asset) => ({
			...asset,
			title: asset.title.replaceAll(
				store.replaceWords.replace,
				store.replaceWords.with,
			),
		})),
	}
}

function removeFromFirst(store) {
	return {
		...store,
		renamedAssets: store.renamedAssets.map((asset) => ({
			...asset,
			title: asset.title.substring(store.removeWordsFromFirst),
		})),
	}
}

function removeFromLast(store) {
	if (!store.removeWordsFromLast) {
		return store
	}

	return {
		...store,
		renamedAssets: store.renamedAssets.map((asset) => ({
			...asset,
			title: asset.title.slice(0, store.removeWordsFromLast * -1),
		})),
	}
}

function removeFromRange(store) {
	if (
		store.removeWordsFromRange.to > 0 ||
		store.removeWordsFromRange.from <= -1
	) {
		if (store.removeWordsFromFirst >= store.removeWordsFromRange.to) {
			return store
		}

		return {
			...store,
			renamedAssets: store.renamedAssets.map((asset) => {
				const from = () => {
					if (!store.removeWordsFromFirst) {
						return store.removeWordsFromRange.from
					}

					if (store.removeWordsFromFirst >= store.removeWordsFromRange.from) {
						return Math.max(
							Math.min(
								store.removeWordsFromRange.from,
								store.removeWordsFromRange.from - store.removeWordsFromFirst,
							),
							0,
						)
					}

					return store.removeWordsFromRange.from - store.removeWordsFromFirst
				}

				const to = () => {
					if (!store.removeWordsFromFirst) {
						return store.removeWordsFromRange.to
					}

					return store.removeWordsFromRange.to - store.removeWordsFromFirst
				}

				return {
					...asset,
					title: asset.title.slice(0, from()) + asset.title.slice(to()),
				}
			}),
		}
	}

	return store
}

function appendString(store) {
	return {
		...store,
		renamedAssets: store.renamedAssets.map((asset) => ({
			...asset,
			title:
				asset.title.substring(0, store.addWords.atPosition) +
				store.addWords.insert +
				asset.title.substring(store.addWords.atPosition),
		})),
	}
}

function addPrefix(store) {
	return {
		...store,
		renamedAssets: store.renamedAssets.map((asset) => ({
			...asset,
			title: store.addWords.prefix + asset.title,
		})),
	}
}

function updateRenamedAssets(store) {
	return store.renamedAssets
}

const compose =
	(...fns) =>
	(x) =>
		fns.reduce((y, f) => f(y), x)

const renameAssets = compose(
	mapRenamedAssets,
	replaceStringBy,
	removeFromFirst,
	removeFromLast,
	removeFromRange,
	addPrefix,
	appendString,
	updateRenamedAssets,
)

export default renameAssets
