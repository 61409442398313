/**
 * Creates a duplicate-free version of an array of objects,
 * keeping only the latest found object of the given selector.
 *
 * ∵ sometimes data can be duplicated.
 *
 * @param {Array<object>} sourceArr
 * @param {Object} options
 * @param {Array<object> =} options.arrayToBeMerged - Array that should be merged, optional
 * @param {String} [selector='id'] options[].selector - String that will be used as the object identifier.
 * @returns {Array<{}>} Duplicate-free array
 */

function removeArrayDupsByKey(sourceArr = [], { selector = 'id' }) {
	if (!selector || typeof selector !== 'string') {
		throw new Error('selector must be a string')
	}

	if (!Array.isArray(sourceArr)) {
		throw new Error('sourceArr must be an array')
	}

	return [
		...new Map(sourceArr.map((item) => [item?.[selector], item])).values(),
	]
}

// eslint-disable-next-line no-extend-native
Array.prototype.removeDuplicatedObjectsByKey = function ({
	selector = 'id',
} = {}) {
	return removeArrayDupsByKey(this, { selector })
}

export default removeArrayDupsByKey
