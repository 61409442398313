import { createAction } from '@reduxjs/toolkit'

// constants
import librariesEnum from '.enum/libraries'

const { NON_COMPANY, ALL } = librariesEnum

export const initialStateTemplate = {
	isOpen: false,
	order: 'desc',
	perPage: 50,
	currentPage: 1,
	tags: [],
	ids: [],
	favoritesBy: false,
	allFavs: false,
	searchTerm: '',
	publicLinks: [],
	shootDateRange: {
		start: null,
		stop: null,
	},
	clientProvided: null,
	branded: null,
	deliveryNumbers: [],
	untaggedCategories: [],
	showUntagged: false,
	untaggedDeliveryNumbers: false,
	actorsIds: [],
	is_disabled: 'false',
}

export const reducers = {
	setIsOpen(state, { payload: isOpen }) {
		if (isOpen === undefined) {
			state.isOpen = !state.isOpen
		} else {
			state.isOpen = isOpen
		}
	},

	setCurrentPage(state, { payload: currentPage }) {
		state.currentPage = currentPage
	},

	setOrder(state, { payload: order }) {
		state.order = order
	},

	setSortBy(state, { payload: sortBy }) {
		state.sortBy = sortBy
	},

	setPerPage(state, { payload: perPage }) {
		state.perPage = perPage
	},

	resetLastUpdate(state) {
		state.lastUpdate = Date.now()
	},

	setTagFilter(state, { payload: tag }) {
		if (tag && state.tags.some((tagId) => tagId === (tag._id || tag))) {
			state.tags = state.tags.filter((tagId) => tagId !== (tag._id || tag))
		} else if (tag) {
			state.tags.push(tag._id || tag)
		}
		state.currentPage = 1
	},

	setTagsFilter(state, { payload: tags }) {
		state.tags = [...new Set([...state.tags, ...tags])]
		state.currentPage = 1
	},

	unsetTagFilters(state, { payload: tags }) {
		state.tags = state.tags.filter((tagId) => !tags.includes(tagId))
		state.currentPage = 1
	},

	setClientProvidedFilter(state, { payload: clientProvided }) {
		state.clientProvided = clientProvided
	},

	setBrandedFilter(state, { payload: branded }) {
		state.branded = branded
	},

	setInitialState(state, { payload: query }) {
		for (const key in query) {
			state[key] = query[key]

			if (key === 'tags') {
				const tags = query[key]
				state[key] = Array.isArray(tags) ? tags : [tags]
			}

			if (key === 'deliveryNumbers') {
				const deliveryNumbers = query[key]
				state[key] = Array.isArray(deliveryNumbers) ? deliveryNumbers : [deliveryNumbers]
			}

			if (key === 'actorTags') {
				const actorTags = query[key]
				state[key] = Array.isArray(actorTags) ? actorTags : [actorTags]
			}

			if (key === 'actorsIds') {
				const actorsIds = query[key]
				state[key] = Array.isArray(actorsIds) ? actorsIds : [actorsIds]
			}

			if (key === 'untaggedCategories') {
				const untaggedCategories = query[key]
				state[key] = Array.isArray(untaggedCategories) ? untaggedCategories : [untaggedCategories]
			}

			if (key === 'nonClientSpecific') {
				state.companyId = NON_COMPANY
			}
			if (key === ALL) {
				state.companyId = ALL
			}

			if (key === 'shootDateStart') {
				state.shootDateRange = {
					...state.shootDateRange,
					start: query[key],
				}
			}

			if (key === 'shootDateStop') {
				state.shootDateRange = {
					...state.shootDateRange,
					stop: query[key],
				}
			}

			if (key === 'is_disabled') {
				state.is_disabled = typeof query[key] === 'boolean' ? query[key].toString() : query[key]
			}

			if (['qc_ok', 'clientProvided', 'branded'].includes(key)) {
				const urlValue = (value) => {
					if (value === false) {
						return 'no'
					}

					if (value === true) {
						return 'yes'
					}

					return value
				}

				state[key] = urlValue(query[key])
			}
		}
	},

	setUniqueTagPerCategory(state, { payload }) {
		const { category = [], tag } = payload
		const givenTags = category?.tags?.map(({ id }) => id)
		const filteredTags = state.tags.filter((tagId) => !givenTags.includes(tagId))
		state.tags = filteredTags.concat([tag])
	},

	setPublicLink(state, { payload }) {
		if (state.publicLinks.some((linkId) => linkId === payload.link.id)) {
			state.publicLinks = state.publicLinks.filter((linkId) => linkId !== payload.link.id)
		} else {
			state.publicLinks.push(payload.link.id)
		}

		state.ids = payload.links
			.filter((l) => state.publicLinks.includes(l.id))
			.map((l) => l.lib_ids)
			.reduce((arr, i) => arr.concat(i), [])

		state.currentPage = 1
	},

	toggleFavorites(state, { payload: uid }) {
		state.favoritesBy = state.favoritesBy ? null : uid
	},

	toggleAllFavorites(state) {
		state.allFavs = !state.allFavs
	},

	setSearchTerm(state, { payload: searchTerm }) {
		state.searchTerm = searchTerm
	},

	setDeliveryNumbers(state, { payload: newDeliveryNumber }) {
		const isExistentDeliveryNumber = state.deliveryNumbers.some(
			(deliveryNumber) => deliveryNumber === newDeliveryNumber,
		)

		if (isExistentDeliveryNumber) {
			state.deliveryNumbers = state.deliveryNumbers.filter((deliveryNumber) => deliveryNumber !== newDeliveryNumber)
		} else {
			state.deliveryNumbers.push(newDeliveryNumber)
		}
	},

	setShowUntagged(state, { payload: showUntagged }) {
		state.showUntagged = showUntagged
	},

	setUntaggedCategories(state, { payload: category }) {
		if (state.untaggedCategories.some((c) => c === category)) {
			state.untaggedCategories = state.untaggedCategories.filter((c) => c !== category)
		} else {
			state.untaggedCategories.push(category)
		}
		state.currentPage = 1
	},

	setShowUntaggedDeliveryNumbers(state, { payload: value }) {
		state.untaggedDeliveryNumbers = value
	},

	setActorsIds(state, { payload: actorId }) {
		if (state.actorsIds.find((id) => id === actorId)) {
			state.actorsIds = state.actorsIds.filter((id) => id !== actorId)
		} else {
			state.actorsIds.push(actorId)
		}
		state.currentPage = 1
	},

	clearTags(state) {
		state.tags = []
	},

	clearAll(state) {
		return {
			...initialState,
			isOpen: state.isOpen,
		}
	},
}

export function createActions(storeName) {
	const actions = {}
	for (const reducer in reducers) {
		actions[reducer] = createAction(`${storeName}/${reducer}`)
	}

	return actions
}
