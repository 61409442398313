function removeEmptyPropsShalow(obj) {
	const objClone = { ...obj }

	for (const propKey in objClone) {
		if (
			!objClone[propKey] ||
			(Array.isArray(objClone[propKey]) && !objClone[propKey].length)
		) {
			delete objClone[propKey]
		}
	}

	return objClone
}

function removeEmptyPropsDeep(obj) {
	const objClone = { ...obj }
	const propNames = Object.getOwnPropertyNames(objClone)
	for (let i = 0; i < propNames.length; i++) {
		const propName = propNames[i]
		if (objClone[propName] === null || objClone[propName] === undefined) {
			delete objClone[propName]
		}
	}

	return objClone
}

function removeEmptyProps(obj, deep = false) {
	if (deep) {
		return removeEmptyPropsDeep(obj)
	}

	return removeEmptyPropsShalow(obj)
}

export default removeEmptyProps
