import { createSlice } from '@reduxjs/toolkit'

import { generateKeys } from '.helpers'

const initialState = {
	toasts: [],
}

const toastStore = createSlice({
	name: 'TOASTS',
	initialState,
	reducers: {
		addToast(state, { payload }) {
			if (payload) {
				const toast = {
					type: payload.type,
					position: payload.position,
					title: payload.title,
					subtitle: payload.subtitle,
					detailText: payload.detailText,
					metadata: payload.metadata,
					closable: payload.closable,
					stored: payload.stored,
					DetailComponent: payload.DetailComponent,
					id: payload.id || generateKeys(),
					cta: payload?.cta || null,
				}

				const foundToast =
					payload.id && state.toasts.find((t) => t.id === payload.id)
				if (foundToast) {
					const toastIndex = state.toasts.indexOf(foundToast)
					state.toasts[toastIndex] = toast
				} else {
					state.toasts = state.toasts.concat([toast])
				}
			}
		},

		removeToast(state, { payload: id }) {
			if (id) {
				state.toasts = state.toasts.filter((toast) => toast.id !== id)
			}
		},

		hideToast(state, { payload: id }) {
			const foundToast = id && state.toasts.find((t) => t.id === id)
			if (foundToast) {
				const toast = {
					...foundToast,
					hidden: true,
				}
				const toastIndex = state.toasts.indexOf(foundToast)
				const toasts = [...state.toasts]
				toasts[toastIndex] = toast
				state.toasts = toasts
			}
		},
	},
})

export const { addToast, removeToast, hideToast } = toastStore.actions

export default toastStore.reducer
