import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

const initialState = {
	data: [],
	created: false,
	creating: false,
	loaded: false,
	fetching: false,
	filters: [],
	myFavorites: 0,
	allFavorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	deliveryNumbers: [],
}

const copy = createSlice({
	name: 'LIB/COPY',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		filters(state, { payload }) {
			state.filters = payload
		},

		creating(state, { payload: isCreating }) {
			state.creating = isCreating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		updateCopy(state, { payload: copyUpdated }) {
			if (isObject(copyUpdated)) {
				const foundIndex = state.data.findIndex(
					({ id }) => id === copyUpdated.id,
				)
				state.data.splice(foundIndex, 1, {
					...copyUpdated,
				})
			}
		},

		setMyFavorites(state, { payload: favorites }) {
			state.myFavorites = favorites
		},

		setAllFavorites(state, { payload: favorites }) {
			state.allFavorites = favorites
		},

		setDeliveryNumbers(state, { payload }) {
			state.deliveryNumbers = payload
		}
	},
})

export const Creators = copy.actions

export const {
	fetched,
	fetching,
	creating,
	created,
	setFavorites,
	setMyFavorites,
	setAllFavorites,
	updateCopy,
	setDeliveryNumbers,
} = copy.actions

export default copy.reducer
