const defaultMaxLength = 120
function stringShortner(string, count = defaultMaxLength, useWordBoundary = true) {
	if (!string) {
		return ''
	}

	if (string.length <= count) {
		return string
	}

	const subString = string.substr(0, count - 1)

	return `${
		useWordBoundary
			? subString.substr(0, subString.lastIndexOf(' '))
			: subString
	} ...`
}

export default stringShortner
