import libTalent from './talent.reducer'
import libTalentBulk from './bulk.reducer'
import libTalentCategories from './categories.reducer'
import libTalentFilters from './filters.reducer'
import libTalentSingle from './single.reducer'

const store = {
	libTalent,
	libTalentFilters,
	libTalentBulk,
	libTalentCategories,
	libTalentSingle,
}

export default store
