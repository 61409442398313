import { createSlice } from '@reduxjs/toolkit'

// helpers
import { stringify } from '.helpers/updateLocation'
import removeEmptyProps from '.helpers/removeEmptyProps'


const initialState = {
	docIds: [],
	currentPage: 1,
	currentIndex: null,
	totalOfPages: null,
	currentDocId: null,
	query: ''
}

const librariesSingleNavigation = createSlice({
	name: 'LIBRARIES/SINGLE/NAVIGATION',
	initialState,
	reducers: {
		setDocIds(state, { payload: docs }) {

			if (Array.isArray(docs)) {
				const mappedIds = docs.filter((doc) => doc?.id).map(({ id }) => id)
				const docIds = [...new Set([...state.docIds, ...mappedIds])]
				if (docIds.length) {
					state.docIds = docIds
					const foundIndex = docIds?.findIndex((id) => id === state.currentDocId)
					state.currentIndex = foundIndex
					if (foundIndex === -1) {
						if (state.currentPage < state.totalOfPages) {
							state.currentPage += 1
						}
					}
					// if the initial oc is the last one of the page
					if (foundIndex + 1 === docIds.length) {
						state.currentPage += 1
					}
				}
			}
		},

		setCurrentDocId(state, { payload: docId }) {
			if (typeof docId === 'string') {
				state.currentDocId = docId
			}
		},

		setCurrentPage(state, { payload: currentPage }) {
			if (typeof currentPage === 'number') {
				state.currentPage = currentPage
			}
		},
		setCurrentIndex(state, { payload: index }) {
			if (typeof index === 'number') {
				state.currentIndex = index

				if (index + 1 === state.docIds.length) {
					state.currentPage = state.currentPage + 1
				}

			}
		},
		setTotalOfPages(state, { payload: totalOfPages }) {
			state.totalOfPages = totalOfPages
		},

		setQuery(state, { payload: { filters, currentPage } }) {

			const cleanedFilters = removeEmptyProps({
				...filters,
				shootDateRange:
					Array.isArray(filters?.shootDateRange) &&
						filters?.shootDateRange?.filter(Boolean)?.length
						? filters?.shootDateRange
						: [],
				tags: filters?.tags?.length ? filters?.tags : null,
				companyId: filters?.companyId === 'all' ? null : filters?.companyId,
				page: currentPage,
				perPage: filters?.perPage ?? 100,
				isOpen: null,
				currentPage: null,
				// created_at is the default orderBy value on API
				fields: ['id', filters?.orderBy || 'created_at'],
			})
			const query = stringify(cleanedFilters, { arrayFormat: 'comma' })
			state.query = query
		},
		clear() {
			return initialState
		},
	},
})

export const {
	setDocIds,
	clear,
	setCurrentIndex,
	setCurrentPage,
	setTotalOfPages,
	setCurrentDocId,
	setQuery
} = librariesSingleNavigation.actions

export default librariesSingleNavigation.reducer
