import decodeComponent from 'decode-uri-component'
import splitOnFirst from 'split-on-first'
import strictUriEncode from 'strict-uri-encode'

// inspired on sinderross code.
const isNullOrUndefined = (value) => value === null || value === undefined

function encoderForArrayFormat(options) {
	if (options.arrayFormat === 'comma' || options.arrayFormat === 'separator') {
		return (key) => (result, value) => {
			if (value === null || value === undefined || value.length === 0) {
				return result
			}

			if (result.length === 0) {
				return [[encode(key, options), '=', encode(value, options)].join('')]
			}

			return [
				[result, encode(value, options)].join(options.arrayFormatSeparator),
			]
		}
	}

	return (key) => (result, value) => {
		if (
			value === undefined ||
			(options.skipNull && value === null) ||
			(options.skipEmptyString && value === '')
		) {
			return result
		}

		if (value === null) {
			return [...result, encode(key, options)]
		}

		return [
			...result,
			[encode(key, options), '[]=', encode(value, options)].join(''),
		]
	}
}

function parserForArrayFormat(options) {
	let result

	switch (options.arrayFormat) {
		case 'index':
			return (key, value, accumulator) => {
				let currKey = key
				result = /\[(\d*)\]$/.exec(currKey)

				currKey = key.replace(/\[\d*\]$/, '')

				if (!result) {
					accumulator[currKey] = value
					return
				}

				if (accumulator[currKey] === undefined) {
					accumulator[currKey] = {}
				}

				accumulator[currKey][result[1]] = value
			}

		case 'bracket':
			return (key, value, accumulator) => {
				let currKey = key
				result = /(\[\])$/.exec(currKey)
				currKey = currKey.replace(/\[\]$/, '')

				if (!result) {
					accumulator[currKey] = value
					return
				}

				if (accumulator[currKey] === undefined) {
					accumulator[currKey] = [value]
					return
				}

				accumulator[currKey] = [].concat(accumulator[currKey], value)
			}

		case 'comma':
		case 'separator':
			return (key, value, accumulator) => {
				let val = value
				const isArray =
					typeof val === 'string' && val.includes(options.arrayFormatSeparator)
				const isEncodedArray =
					typeof val === 'string' &&
					!isArray &&
					decode(val, options).includes(options.arrayFormatSeparator)
				val = isEncodedArray ? decode(val, options) : val
				const newValue =
					isArray || isEncodedArray
						? val
								.split(options.arrayFormatSeparator)
								.map((item) => decode(item, options))
						: val === null
						? val
						: decode(val, options)
				accumulator[key] = newValue
			}

		default:
			return (key, value, accumulator) => {
				if (accumulator[key] === undefined) {
					accumulator[key] = value
					return
				}

				accumulator[key] = [].concat(accumulator[key], value)
			}
	}
}

function validateArrayFormatSeparator(value) {
	if (typeof value !== 'string' || value.length !== 1) {
		throw new TypeError('Unsuported array separator')
	}
}

function encode(value, options) {
	if (options.encode) {
		return options.strict ? strictUriEncode(value) : encodeURIComponent(value)
	}

	return value
}

function decode(value, options) {
	if (options.decode) {
		return decodeComponent(value)
	}

	return value
}

function keysSorter(input) {
	if (Array.isArray(input)) {
		return input.sort()
	}

	if (typeof input === 'object') {
		return keysSorter(Object.keys(input))
			.sort((a, b) => Number(a) - Number(b))
			.map((key) => input[key])
	}

	return input
}

function removeHash(input) {
	let newInput = input
	const hashStart = newInput.indexOf('#')

	if (hashStart !== -1) {
		newInput = newInput.slice(0, hashStart)
	}

	return newInput
}

function extract(input) {
	const newInput = removeHash(input)
	const queryStart = newInput.indexOf('?')

	if (queryStart === -1) {
		return ''
	}

	return newInput.slice(queryStart + 1)
}

function parseValue(value, options) {
	let parsedValue = value
	if (
		options.parseNumbers &&
		!Number.isNaN(Number(parsedValue)) &&
		typeof parsedValue === 'string' &&
		parsedValue.trim() !== ''
	) {
		parsedValue = Number(parsedValue)
	} else if (
		options.parseBools &&
		parsedValue !== null &&
		(parsedValue.toLowerCase() === 'true' ||
			parsedValue.toLowerCase() === 'false')
	) {
		parsedValue = parsedValue.toLowerCase() === 'true'
	}

	return parsedValue
}

export function parse(query, options = {}) {
	const keysIgnoreParser = ['companyid']

	let newQuery = query
	const opts = {
		decode: true,
		sort: true,
		arrayFormat: 'comma',
		arrayFormatSeparator: ',',
		parseNumbers: true,
		parseBools: true,
		...options,
	}

	validateArrayFormatSeparator(opts.arrayFormatSeparator)

	const formatter = parserForArrayFormat(opts)
	const ret = Object.create(null)

	if (typeof query !== 'string') {
		return ret
	}

	newQuery = newQuery.trim().replace(/^[?#&]/, '')

	if (!newQuery) {
		return ret
	}

	for (const param of query.split('&')) {
		let [key, value] = splitOnFirst(
			opts.decode ? param.replace(/\+/g, ' ') : param,
			'=',
		)

		// Missing `=` should be `null`:
		// http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
		value =
			value === undefined
				? null
				: ['comma', 'separator'].includes(opts.arrayFormat)
				? value
				: decode(value, opts)
		formatter(decode(key, opts), value, ret)
	}

	for (const key of Object.keys(ret)) {
		const value = ret[key]

		if (typeof value === 'object' && value !== null) {
			for (const k of Object.keys(value)) {
				const ignoreParser = keysIgnoreParser.includes(k.toLowerCase())
				value[k] = ignoreParser ? value[k] : parseValue(value[k], options)
			}
		} else {
			const ignoreParser = keysIgnoreParser.includes(key.toLowerCase())
			ret[key] = ignoreParser ? value : parseValue(value, options)
		}
	}

	if (options.sort === false) {
		return ret
	}

	return (
		options.sort === true
			? Object.keys(ret).sort()
			: Object.keys(ret).sort(options.sort)
	).reduce((result, key) => {
		const value = ret[key]
		if (Boolean(value) && typeof value === 'object' && !Array.isArray(value)) {
			// Sort object keys, not values
			result[key] = keysSorter(value)
		} else {
			result[key] = value
		}

		return result
	}, Object.create(null))
}

export function stringify(object, options = {}) {
	if (!object) {
		return ''
	}

	const newOpts = {
		encode: true,
		strict: true,
		arrayFormat: 'none',
		parseNumbers: true,
		arrayFormatSeparator: ',',
		...options,
	}

	validateArrayFormatSeparator(newOpts.arrayFormatSeparator)

	const shouldFilter = (key) =>
		(newOpts.skipNull && isNullOrUndefined(object[key])) ||
		(newOpts.skipEmptyString && object[key] === '')

	const formatter = encoderForArrayFormat(newOpts)

	const objectCopy = {}

	for (const key of Object.keys(object)) {
		if (!shouldFilter(key)) {
			objectCopy[key] = object[key]
		}
	}

	const keys = Object.keys(objectCopy)

	if (newOpts.sort !== false) {
		keys.sort(newOpts.sort)
	}

	return keys
		.map((key) => {
			const value = object[key]

			if (value === undefined) {
				return ''
			}

			if (value === null) {
				return encode(key, newOpts)
			}

			if (Array.isArray(value)) {
				return value.reduce(formatter(key), []).join('&')
			}

			return `${encode(key, newOpts)}=${encode(value, newOpts)}`
		})
		.filter((x) => x.length > 0)
		.join('&')
}

export function parseUrl(url, options = {}) {
	const newOpts = { decode: true, ...options }
	const [url_, hash] = splitOnFirst(url, '#')

	return Object.assign(
		{
			url: url_.split('?')[0] || '',
			query: parse(extract(url), options),
		},
		newOpts && newOpts.parseFragmentIdentifier && hash
			? { fragmentIdentifier: decode(hash, newOpts) }
			: {},
	)
}

function updateLocation(encodedQuery, location, stringifyOptions) {
	const encodedSearchString = stringify(encodedQuery, stringifyOptions)

	const search = encodedSearchString.length ? `?${encodedSearchString}` : ''
	const href = parseUrl(location?.href || '').url + search

	const newLocation = {
		...location,
		href,
		search,
		query: encodedQuery,
	}

	return newLocation
}

export default updateLocation
