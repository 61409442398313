import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
	loadingNewComment: false,
	loadingNewReply: false,
}

const talentComments = createSlice({
	name: 'talentComments',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (Array.isArray(payload)) {
				state.data = payload
			}
			state.fetching = false
			state.loaded = true
			state.loadingNewComment = false
			state.loadingNewReply = false
		},

		fetching(state) {
			state.fetching = true
		},

		setLoadingNewComment(state, { payload }) {
			state.loadingNewComment = payload
		},

		setLoadingNewReply(state, { payload }) {
			state.loadingNewReply = payload
		},

		loaded(state) {
			state.loaded = true
		},

		clear(state) {
			state.data = []
			state.fetching = false
			state.loaded = false
		},
	},
})

export const Creators = talentComments.actions

export const { clear } = talentComments.actions

export default talentComments.reducer
