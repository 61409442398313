export const reducers = {
	addSelected(state, { payload: libItem }) {
		state.selected = [...state.selected, libItem]
	},
	addMultipleSelected(state, { payload: libArr }) {
		const unique = (a) => {
			const seen = {}
			return a.filter((item) =>
				seen.hasOwnProperty(item.id) ? false : (seen[item.id] = true),
			)
		}
		const newSelected = [...state.selected, ...libArr]
		state.selected = unique(newSelected)
	},
	removeSelected(state, { payload: libItem }) {
		state.selected = state.selected.filter(
			(stateItem) => stateItem.id !== libItem.id,
		)
		if (!state.selected.length) {
			state.showOkButton = false
			state.showCompanySelector = false
		}
	},

	updateSelected(state, { payload: libItem }) {
		state.selected = state.selected.map((item) => {
			if (libItem.id === item.id) {
				return libItem
			}
			return item
		})
	},

	removeMultipleSelected(state, { payload: libArr }) {
		state.selected = state.selected.filter(
			(stateItem) => !libArr.some((newItem) => newItem.id === stateItem.id),
		)
	},
	setSelected(state, { payload: libArr }) {
		state.selected = libArr
	},

	clearSelected(state) {
		state.selected = []
		state.showOkButton = false
		state.showCompanySelector = false
	},
	setShowCompanySelector(state, { payload: value }) {
		if (value !== undefined) {
			state.showCompanySelector = value
		} else {
			state.showCompanySelector = !state.showCompanySelector
		}
	},
	setShowOkButton(state, { payload: value }) {
		if (value !== undefined) {
			state.showOkButton = value
		} else {
			state.showOkButton = !state.showOkButton
		}
	},
	onBulkTagsUpdated(state, { payload: replacementCategory }) {
		const newSelected = state.selected.map((libItem) => {
			if (libItem.categories) {
				let isNewCategory = true
				const categories = libItem.categories.map((category) => {
					if (category.slug === replacementCategory.slug) {
						isNewCategory = false
						return { ...replacementCategory }
					}
					return { ...category }
				})
				if (isNewCategory) {
					categories.push(replacementCategory)
				}
				return { ...libItem, categories }
			}
			return { ...libItem }
		})
		state.selected = [...newSelected]
	},
}
