import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	keywordsBySlug: [],
	loaded: false,
	fetching: false
}

const keywords = createSlice({
	name: 'keywords',
	initialState,
	reducers: {
		fetchedKeywordsBySlug(state, { payload }) {
			state.keywordsBySlug = payload
			state.loaded = true
			state.fetching = false
		},
		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		clear(state) {
			Object.assign(state, initialState)
		},
	},
})

export const Creators = keywords.actions

export default keywords.reducer
