import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
	currentCompany: null,
	initialCompany: null,
}

const customCategoriesStore = createSlice({
	name: 'customCategoriesSore',
	initialState,
	reducers: {
		fetched(state, { payload: customCategories }) {
			if (Array.isArray(customCategories)) {
				state.data = customCategories
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		setCurrentCompany(state, { payload: company }) {
			if (company) {
				state.currentCompany = company
			}
		},

		setInitialCompany(state, { payload: company }) {
			if (company) {
				state.initialCompany = company
			}
		},
	},
})

export const Creators = customCategoriesStore.actions

export const { fetched, fetching, loaded } = customCategoriesStore.actions

export default customCategoriesStore.reducer
