import { createSlice } from '@reduxjs/toolkit'

import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'

const initialState = {
	data: null,
	loaded: false,
	fetching: false,
	filters: [],
	keyboard_blocked: false,
	assetsBkp: [],
}

const singleAd = createSlice({
	name: 'LIB/ADS/SINGLE',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.id) {
				state.data = payload
				state.fetched = true
				state.fetching = false
				state.updating = false
				state.filters = []
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		clear() {
			return initialState
		},

		blockKeyboard(state) {
			state.keyboard_blocked = true
		},

		unlockKeyboard(state) {
			state.keyboard_blocked = false
		},

		restateAssetsBkp(state) {
			state.data.assets = state.data.assetsBkp
		},

		removeAsset(state, { payload: asset }) {
			if (asset?.id && asset?.type) {
				state.data.assetsBkp = state.data.assets
				state.data.assets = state.data.assets.filter(a => a.id !== asset.id)
			}
		},

		addAsset(state, { payload: asset }) {
			if (asset?.id && asset?.type) {
				state.data.assets = removeArrayDupsByKey([...state.data.assets, asset], 'id')
			}
		},

		updateAttribute(state, { payload: property }) {
			if (property.attribute) {
				state.data[property.attribute] = property.value
			}
		},

		setAssets(state, { payload: assets }) {
			if (Array.isArray(assets)) {
				state.data.assets = assets
			}
		},

		setTags(state, { payload: tags }) {
			if (Array.isArray(tags)) {
				state.data.tags = tags
			}
		}
	},
})

export const Creators = singleAd.actions

export const { fetched, fetching, clear, blockKeyboard, unlockKeyboard, setAssets, addAsset, removeAsset, setTags } = singleAd.actions

export default singleAd.reducer
