const debouceDefaultValue = 250
/**
 * Calls the same function once at giving time
 * @param {Function} callback
 * @param {Number} time
 * @returns {Function} function
 */
const debounce = (callback, time = debouceDefaultValue) => {
	let interval
	return (...args) => {
		clearTimeout(interval)
		interval = setTimeout(() => {
			interval = null
			callback(...args)
		}, time)
	}
}

export default debounce
