import libImages from './images.reducer'
import libImagesBulk from './bulk.reducer'
import libImagesCategories from './categories.reducer'
import libImagesFilters from './filters.reducer'
import libImagesSingle from './single.reducer'

const store = {
	libImages,
	libImagesFilters,
	libImagesBulk,
	libImagesCategories,
	libImagesSingle,
}

export default store
