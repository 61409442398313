import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	uploadedFiles: [],
	uploaded: false,
	uploading: false,
}

const files = createSlice({
	name: 'files',
	initialState,
	reducers: {
		submitted(state, { payload }) {
			if (payload) {
				state.uploadedFiles = payload.data
				state.loaded = true
				state.uploading = false
			}
		},

		uploading(state, { payload }) {
			state.uploading = payload
		},

		uploaded(state, { payload }) {
			state.uploaded = payload
		},
	},
})

export const Creators = files.actions

export default files.reducer
