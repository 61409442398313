import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
}

const copySingle = createSlice({
	name: 'copySingle',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload) {
				state.data = payload?.data
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		clear() {
			return initialState
		},
		setIsBranded(state) {
			state.data.branded = !state.data?.branded
		},
		setBusinessUnit(state, { payload: businessUnit }) {
			state.data.business_unit = businessUnit
		},

		updateAttribute(state, { payload: property }) {
			if (property.attribute) {
				state.data[property.attribute] = property.value
			}
		},
	},
})

export const Creators = copySingle.actions

export const { setBusinessUnit, setIsBranded } = copySingle.actions

export default copySingle.reducer
