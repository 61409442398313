import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: {},
	fetching: false,
}

const users = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUserData(state, { payload }) {
			if (payload.data && payload.userId) {
				state.data[payload.userId] = payload.data
			}
		},
	},
})

export const Creators = users.actions
export const { setUserData } = users.actions

export default users.reducer
