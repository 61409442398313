import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/library.template'

const initialState = {
	...initialStateTemplate,
	data: [],
	dataBkp: [],
	creating: false,
	created: false,
	loaded: false,
	fetching: false,
	filters: [],
	favorites: 0,
	allFavorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	fetched_actors: false,
	fetched_locations: false,
	fetching_actors: false,
	fetching_locations: false,
	actors: [],
	locations: [],
	updating_actor: false,
}

const talent = createSlice({
	name: 'LIB/TALENT',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		updateTalent(state, { payload: talentUpdated }) {
			if (isObject(talentUpdated)) {
				const foundIndex = state.data.findIndex(
					({ id }) => id === talentUpdated.id,
				)
				state.data.splice(foundIndex, 1, {
					...talentUpdated,
					companies: talentUpdated?.companies[0]?.id
						? talentUpdated?.companies.map((e) => e.id)
						: talentUpdated?.companies,
				})
			}
		},

		removeTalent(state, { payload: talentId }) {
			state.data = state.data.filter(({ id }) => id !== talentId)
		},

		clearTalentBkp(state) {
			state.dataBkp = []
		},

		createTalentBkp(state) {
			state.dataBkp = [...state.data]
		},

		restoreTalentData(state) {
			state.data = [...state.dataBkp]
		},

		setFavorites(state, { payload: favorites }) {
			state.favorites = favorites
		},

		setAllFavorites(state, { payload: favorites }) {
			state.allFavorites = favorites
		},
	},
})

export const Creators = talent.actions

export const {
	fetched,
	fetching,
	updateTalent,
	setFavorites,
	setAllFavorites,
	removeTalent,
	clearTalentBkp,
	restoreTalentData,
	createTalentBkp,
} = talent.actions

export default talent.reducer
