import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selected: [],
	showOkButton: false,
	showCompanySelector: false,
}

const selectsBulk = createSlice({
	name: 'SELECTS.BULK',
	initialState,
	reducers: {
		setSelected(state, { payload: value }) {
			state.selected = value
		},
		addSelected(state, { payload: libItem }) {
			state.selected = [...state.selected, libItem]
		},
		addMultipleSelected(state, { payload: libArr }) {
			state.selected = [
				...state.selected,
				...libArr,
			].removeDuplicatedObjectsByKey({
				selector: 'id',
			})
		},
		updateSelected(state, { payload: libItem }) {
			state.selected = state.selected.map((item) => {
				if (libItem.id === item.id) {
					return libItem
				}
				return item
			})
		},

		removeSelected(state, { payload: libItem }) {
			state.selected = state.selected.filter(
				(stateItem) => stateItem.id !== libItem.id,
			)
			if (!state.selected.length) {
				state.showOkButton = false
				state.showCompanySelector = false
			}
		},
		removeMultipleSelected(state, { payload: libArr }) {
			state.selected = state.selected.filter(
				(stateItem) => !libArr.some((newItem) => newItem.id === stateItem.id),
			)
		},
		clearSelected(state) {
			state.selected = []
			state.showOkButton = false
			state.showCompanySelector = false
		},
		setShowCompanySelector(state, { payload: value }) {
			if (value !== undefined) {
				state.showCompanySelector = value
			} else {
				state.showCompanySelector = !state.showCompanySelector
			}
		},
		setShowOkButton(state, { payload: value }) {
			if (value !== undefined) {
				state.showOkButton = value
			} else {
				state.showOkButton = !state.showOkButton
			}
		},
		onBulkTagsUpdated(state, { payload: replacementCategory }) {
			const newSelected = state.selected.map((libItem) => {
				if (libItem.categories) {
					let isNewCategory = true
					const categories = libItem.categories.map((category) => {
						if (category.slug === replacementCategory.slug) {
							isNewCategory = false
							return { ...replacementCategory }
						}
						return { ...category }
					})
					if (isNewCategory) {
						categories.push(replacementCategory)
					}
					return { ...libItem, categories }
				}
				return { ...libItem }
			})
			state.selected = [...newSelected]
		},
	},
})

export const {
	setSelected,
	addSelected,
	addMultipleSelected,
	removeSelected,
	removeMultipleSelected,
	clearSelected,
	setShowOkButton,
	setShowCompanySelector,
	onBulkTagsUpdated,
	updateSelected
} = selectsBulk.actions

export default selectsBulk.reducer
