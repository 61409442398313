import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	customCategories: [],
	loaded: false,
	fetching: false,
}

const libCopyCategories = createSlice({
	name: 'LIB/COPY/CATEGORIES',
	initialState,
	reducers: {
		setCategories(state, { payload: categories }) {
			if (Array.isArray(categories)) {
				state.data = categories
				state.loaded = true
				state.fetching = false
			}
		},

		setCustomCategories(state, { payload: categories }) {
			if (Array.isArray(categories)) {
				state.customCategories = categories
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const { setCategories, fetching, loaded, setCustomCategories } =
	libCopyCategories.actions

export default libCopyCategories.reducer
