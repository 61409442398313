import { captureException } from '@sentry/react'
import { createSlice, current } from '@reduxjs/toolkit'

import renameAssets from './renamerAssets.helpers'

const initialState = {
	assets: [],
	renamedAssets: [],
	removeWordsFromFirst: 0,
	removeWordsFromLast: 0,
	removeWordsFromRange: { from: 0, to: 0 },
	addWords: {
		prefix: '',
		insert: '',
		atPosition: 0,
	},
	replaceWords: {
		replace: '',
		with: '',
	},
	modalIsOpen: false,
	loading: true,
	allowSubmit: false,
}

const renamerTool = createSlice({
	name: 'RENAMER',
	initialState,
	reducers: {
		setInitialState: (state, { payload: assets }) => {

			if (Array.isArray(assets)) {
				const mappedAssets = assets.map((asset) => ({
					id: asset.id,
					title: asset.title || asset.name,
				})).map(asset => ({
					...asset,
					title: asset.title.replace(/\.[^/.]+$/, ''),
					ext: asset.title.substr(asset.title.lastIndexOf('.')),
				}))

				state.assets = mappedAssets
				state.renamedAssets = mappedAssets
			} else {
				captureException('setInitialState: payload is not an array')
			}

			state.renamedAssets = renameAssets(current(state))
		},

		setAssets(state, { payload: assets }) {
			if (Array.isArray(assets)) {
				state.assets = assets
			}
		},

		removeAssetById(state, { payload: id }) {
			state.assets = state.assets.filter((asset) => asset.id !== id)
			state.renamedAssets = state.renamedAssets.filter((asset) => asset.id !== id)
			if (state.assets.length === 0) {
				state.modalIsOpen = false
			}
		},

		setRenamedAssets(state, { payload: renamedAssets }) {
			if (Array.isArray(renamedAssets)) {
				state.renamedAssets = renamedAssets
			}
		},

		setFromFirst(state, { payload: fromFirst }) {
			if (typeof fromFirst === 'object') {
				state.fromFirst = fromFirst
			}
		},

		setReplaceWordsReplace(state, { payload: replace }) {
			if (typeof replace === 'string') {
				state.replaceWords.replace = replace
				state.renamedAssets = renameAssets(current(state))

			}
		},

		setReplaceWordsWith(state, { payload: replaceWith }) {
			if (typeof replaceWith === 'string') {
				state.replaceWords.with = replaceWith
				if (state.replaceWords?.replace) {
					state.renamedAssets = renameAssets(current(state))
				}
			}
		},

		setRemoveWordsFromFirst(state, { payload: to }) {
			if (typeof to === 'number' && !isNaN(to)) {
				state.removeWordsFromFirst = to
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setRemoveWordsFromLast(state, { payload: to }) {
			if (typeof to === 'number' && !isNaN(to)) {
				state.removeWordsFromLast = to
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setRemoveWordsFromRangeFrom(state, { payload: from }) {
			if (typeof from === 'number' && !isNaN(from)) {
				state.removeWordsFromRange.from = from
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setRemoveWordsFromRangeTo(state, { payload: to }) {
			if (typeof to === 'number' && !isNaN(to)) {
				state.removeWordsFromRange.to = to
				state.renamedAssets = renameAssets(current(state))
			}
		},

		toogleModalState(state) {
			state.modalIsOpen = !state.modalIsOpen
		},

		setModalState(state, { payload: modalIsOpen }) {
			if (typeof modalIsOpen === 'boolean') {
				state.modalIsOpen = modalIsOpen
			}
		},

		setPrefixToInsert(state, { payload: prefix }) {
			if (typeof prefix === 'string') {
				state.addWords.prefix = prefix
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setWordToInsertAtPosition(state, { payload: wordToInsert }) {
			if (typeof wordToInsert === 'string') {
				state.addWords.insert = wordToInsert
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setPositionToInsertWord(state, { payload: position }) {
			if (typeof position === 'number' && !isNaN(position)) {
				state.addWords.atPosition = position
				state.renamedAssets = renameAssets(current(state))
			}
		},

		setLoadingState(state, { payload: loading }) {
			if (typeof loading === 'boolean') {
				state.loading = loading
			}
		},

		clear(state) {
			return {
				...initialState,
				assets: state.assets,
				renamedAssets: state.renamedAssets,
				modalIsOpen: state.modalIsOpen,
				loading: state.loading,
			}
		},

		reset() {
			return initialState
		},

		setAllowSubmit(state, { payload: allowSubmit }) {
			if (typeof allowSubmit === 'boolean') {
				state.allowSubmit = allowSubmit
			}
		}
	},
})

export const Creators = renamerTool.actions

export const {
	setRenamedAssets,
	removeAssetById,
	setAssets,
	setInitialState,
	reset,
	setReplaceWordsReplace,
	setReplaceWordsWith,
	toogleModalState,
	setRemoveWordsFromFirst,
	setRemoveWordsFromLast,
	setRemoveWordsFromRangeFrom,
	setRemoveWordsFromRangeTo,
	setPrefixToInsert,
	setWordToInsertAtPosition,
	setPositionToInsertWord,
	clear,
	setLoadingState,
	setModalState,
	setAllowSubmit
} = renamerTool.actions

export default renamerTool.reducer
