import { createSlice } from '@reduxjs/toolkit'

import { createActions, initialStateTemplate, reducers } from '.reducers/libraries/filters.template'

const initialState = {
	name: '',
	...initialStateTemplate,
}

const libVoiceOverFilters = createSlice({
	name: 'LIB/VOICEOVER/FILTERS',
	initialState,
	reducers: {
		...reducers,
	},
})

export const {
	setCurrentPage,
	setIsOpen,
	setPerPage,
	setOrder,
	setSortBy,
	resetLastUpdate,
	setCompanyId,
	setTagFilter,
	setPublicLink,
	setTagsFilter,
	resetTagsFilter,
	setInitialState,
	toggleFavorites,
	setSearchTerm,
	unsetTagFilters,
	toggleAllFavorites,
	setClientProvidedFilter,
	setBrandedFilter,
	setShowUntagged,
	setUntaggedCategories,
	clearTags,
} = createActions(libVoiceOverFilters.name)

export default libVoiceOverFilters.reducer
