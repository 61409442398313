import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	loaded: false,
	fetching: false,
	bkp: {},
}

const libVoiceOverSingles = createSlice({
	name: 'LIB/VOICEOVER/SINGLE',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.id) {
				state.data = payload
				state.loaded = true
				state.fetching = false
				state.updating = false
			}
		},

		fetching(state) {
			state.loaded = false
			state.fetching = true
			state.updating = false
		},

		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		clear(state) {
			state.data = false
			state.loaded = false
			state.loading = false
		},

		setCompanyId(state, { payload: companyId }) {
			state.bkp.company_id = state.data.company_id
			state.data.company_id = companyId
		},

		resetCompanyId(state) {
			state.data.company_id = state.bkp.company_id
		},
		setIsClientProvided(state, { payload: isClientProvided }) {
			if (state?.data?.id) {
				state.data.client_provided = isClientProvided
			}
		},
		setIsBranded(state) {
			state.data.branded = !state.data?.branded
		},
		setBusinessUnit(state, { payload: businessUnit }) {
			state.data.business_unit = businessUnit
		},
		setTalentId(state, { payload: TalentId }) {
			state.data.talent_id = TalentId
		},
	},
})

export const Creators = libVoiceOverSingles.actions

export const { fetched, fetching, clear, setCompanyID } = libVoiceOverSingles.actions

export default libVoiceOverSingles.reducer
