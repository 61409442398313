import { createSlice } from '@reduxjs/toolkit'

// helpers
import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'

const initialState = {
	allForfootage: null,
	allForads: null,

	historySearched: [],
	historyViewed: [],
}

const librariesStore = createSlice({
	name: 'LIBRARIES',
	initialState,
	reducers: {
		setHistorySearched(state, { payload: item }) {
			if (item && !state.historySearched.some(({ term }) => term === item)) {
				// eslint-disable-next-line no-magic-numbers
				if (state.historySearched.length >= 10) {
					state.historySearched.splice(0, 1)
				}
				state.historySearched.push(item)
			}
		},

		setHistoryViewed(state, { payload: item }) {
			if (item.id && !state.historyViewed.some(({ id }) => id === item.id)) {
				// eslint-disable-next-line no-magic-numbers
				if (state.historyViewed.length >= 10) {
					state.historyViewed.splice(0, 1)
				}
				if (item) {
					state.historyViewed.push(item)
				}
			}
		},

		setHistoryViewedBulk(state, { payload: history }) {
			if (Array.isArray(history)) {
				state.historyViewed = removeArrayDupsByKey(history, { selector: 'id' })
			}
		},

		setHistorySearchedBulk(state, { payload: searched }) {
			if (Array.isArray(searched)) {
				state.historySearched = removeArrayDupsByKey(searched, {
					selector: 'history',
				}).map(({ history }) => history)
			}
		},
	},
})

export const {
	setLibraryCompanyId,
	setHistorySearched,
	setHistoryViewed,
	setHistoryViewedBulk,
	setHistorySearchedBulk,
} = librariesStore.actions

export default librariesStore.reducer
