import { createAction } from '@reduxjs/toolkit'

export const initialStateTemplate = {
	allFavorites: 0,
	favorites: 0,
	data: [],
	bkp: [],
}

export const reducers = {
	setAllFavorites(state, { payload: allFavorites }) {
		state.allFavorites = allFavorites
	},

	setFavorites(state, { payload: myFavorites }) {
		state.favorites = myFavorites
	},



	createAssetsBkp(state, { payload: assetsIds }) {
		if (Array.isArray(assetsIds)) {
			const assets = state.data.filter((f) => assetsIds.includes(f.id))
			state.bkp = assets
		}
	},

	restoreBkp(state) {
		for (const assetBkp of state.bkp) {
			const assetIndex = state.data.findIndex(({ id }) => id === assetBkp?.id)
			if (assetIndex > -1) {
				state.data[assetIndex] = assetBkp
			}
		}
	},

	clearBkp(state) {
		state.bkp = []
	},

	updateAsset(state, { payload: updatedAsset }) {
		const assetIndex = state.data.findIndex(({ id }) => id === updatedAsset?.id)
		if (assetIndex > -1) {
			state.data[assetIndex] = {
				...state.data[assetIndex],
				...updatedAsset,
				id: state.data[assetIndex].id,
			}
		}
	},
}

export function createActions(storeName) {
	const actions = {}
	for (const reducer in reducers) {
		actions[reducer] = createAction(`${storeName}/${reducer}`)
	}

	return actions
}
