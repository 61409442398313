import libVoiceOver from './voiceOver.reducer'
import libVoiceOverBulk from './bulk.reducer'
import libVoiceOverCategories from './categories.reducer'
import libVoiceOverFilters from './filters.reducer'
import libVoiceOverSingle from './single.reducer'

const store = {
	libVoiceOver,
	libVoiceOverFilters,
	libVoiceOverBulk,
	libVoiceOverCategories,
	libVoiceOverSingle,
}

export default store
