import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
}

const musicCustomCategories = createSlice({
	name: 'musicCustomCategories',
	initialState,
	reducers: {
		fetched(state, { payload: customCategories }) {
			if (Array.isArray(customCategories)) {
				state.data = customCategories
			}
			state.loaded = true
			state.fetching = false
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const Creators = musicCustomCategories.actions

export default musicCustomCategories.reducer
