import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: [],
    fetching: false,
    loaded: false,
}

const new_tagsReducer = createSlice({
    name: 'new_tagsReducer',
    initialState,
    reducers: {
        fetched(state, { payload: data }) {
            if (Array.isArray(data)) {
                state.data = data
                state.loaded = true
                state.fetching = false
            }
        },

        fetching(state, { payload: isFetching }) {
            state.fetching = isFetching
        },

        clear(state) {
            state.data = []
            state.page = 1
            state.hasNextPage = false
        }
    },
})

export const { clear, fetched, fetching } = new_tagsReducer.actions

export default new_tagsReducer.reducer
