import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'
import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'


import enumsLibraries from '.enum/libraries'

import { annoyingAPIinconsistence } from '.enum/tagRelationshipsDocType'

const initialState = {
	loaded: false,
	fetching: false,
	document: {},
	documents: [],
	documentCategories: [],
	documentType: '',
	categories: [],
	currentCategory: enumsLibraries.IDLE,
	categoryToAdd: '',
	tableData: [],
	tagsToAdd: [],
	tagsToCreate: [],
	taggingCompanyId: null,
	carouselSlideTo: 0,
	loading: false,
	activeTags: [],
	countedAdsByTag: 0,
	countedAdsByCategory: 0,
	tags: {},
	tagCreationError: null,
	tagCreationStatus: enumsLibraries.IDLE,
	tagAttachingStatus: enumsLibraries.IDLE,
	tagAttachingError: null,
	tagsToDelete: [],
	categoryToDelete: null,
	deletingCategoryStatus: enumsLibraries.IDLE,
}

const new_tagManagement = createSlice({
	name: 'new_tagManagement',
	initialState,
	reducers: {
		setLoading(state, { payload: loading }) {
			state.loading = loading
		},
		setFetching(state, { payload: fetching }) {
			if (fetching) {
				state.fetching = fetching
			}
		},
		setDocument(state, { payload: document }) {
			if (isObject(document)) {
				state.document = document
			}
		},

		setActiveTags(state, { payload: activeTags }) {
			if (Array.isArray(activeTags)) {
				state.activeTags = activeTags
			}
		},

		setDocuments(state, { payload: documents }) {
			if (Array.isArray(documents)) {
				state.documents = documents
			}
		},

		updateDocument(state, { payload: doc }) {
			const documentIndex = state.documents.findIndex(({ id }) => id === doc.id)
			if (documentIndex >= 0) {
				state.documents[documentIndex] = doc
			}
		},

		addTagToDocument(state, { payload: tag }) {
			if (isObject(tag)) {
				state.document.tags.push(tag)
			}
		},

		setDocumentType(state, { payload: documentType }) {
			if (typeof documentType === 'string') {
				const docType = documentType === 'selects' ? 'selects' : annoyingAPIinconsistence.get(documentType)
				state.documentType = docType
			}
		},

		setTaggingCompanyId(state, { payload: companyId }) {
			if (typeof companyId === 'string') {
				state.taggingCompanyId = companyId
			}
		},

		setDocumentCategories(state, { payload: documentCategories }) {
			if (Array.isArray(documentCategories)) {
				state.documentCategories = documentCategories
			}
		},

		setCategories(state, { payload: categories }) {
			if (Array.isArray(categories)) {
				state.categories = categories
			}
		},

		setCategoryTag(state, { payload }) {
			if (Array.isArray(payload.tags) && payload.categoryId) {
				state.tags = {
					...state.tags,
					[payload.categoryId]: payload.tags
				}
			}
		},

		updateCategoryTags(state, { payload }) {
			if (Array.isArray(payload.tags)) {
				for (const tag of payload.tags) {
					if (state.tags?.[tag.category_id]) {
						state.tags[tag.category_id] = [tag, ...state.tags[tag.category_id]]
					} else {
						state.tags[tag.category_id] = [tag]
					}
				}
			}
		},

		addCategory(state, { payload: category }) {
			state.categories = removeArrayDupsByKey([...state.categories, category], { selector: 'id' })
		},

		setDeletingCategoryStatus(state, { payload: status }) {
			state.deletingCategoryStatus = status
		},

		removeCategory(state, { payload: categoryId }) {
			state.categories = state.categories.filter(cat => cat.id !== categoryId)
		},

		setCategoryToAdd(state, { payload: category }) {
			state.categoryToAdd = category
		},

		setCurrentCategory(state, { payload: currentCategory }) {
			if (isObject(currentCategory) || currentCategory === null) {
				state.currentCategory = currentCategory
			}
		},

		setCategoryToDelete(state, { payload: categoryId }) {
			state.categoryToDelete = categoryId
		},

		toggleTagsToAdd(state, { payload: tag }) {
			if (isObject(tag)) {
				const tagIsActive = state.tagsToAdd.some(
					({ slug }) => slug === tag.slug,
				)
				if (tagIsActive) {
					state.tagsToAdd = [
						...state.tagsToAdd.filter(({ slug }) => slug !== tag.slug),
					]
				} else {
					state.tagsToAdd.push(tag)
				}
			}
		},

		setTagsToAdd(state, { payload: tags }) {
			if (Array.isArray(tags)) {
				state.tagsToAdd = tags
			}
		},


		setAttatchingError(state, { payload: errorMessage }) {
			state.tagAttachingError = errorMessage
		},


		setTagToCreate(state, { payload: tag }) {
			if (isObject(tag)) {
				state.tagsToCreate.push(tag)
			}
		},

		removeTagToCreate(state, { payload: tag }) {
			if (isObject(tag)) {
				state.tagsToCreate = state.tagsToCreate.filter(
					({ id }) => id !== tag.id,
				)
				if (state.tagCreationError) {
					const tagsWithErrors = state.tagCreationError?.value || []
					const tagWithError = tagsWithErrors.some(({ name }) => tag.name === name)
					if (tagWithError) {
						state.tagCreationError = null
						state.tagCreationStatus = enumsLibraries.IDLE
					}
				}
			}
		},

		clearTagsToCreate(state) {
			state.tagsToCreate = []
		},

		setTableData(state, { payload: tableData }) {
			if (Array.isArray(tableData)) {
				state.tableData = tableData
			}
		},

		clear(state) {
			Object.entries(initialState).forEach(([key, value]) => {
				state[key] = value
			})
		},
		// carousel
		setCarouselSlideTo(state, { payload: index }) {
			if (index === 'last') {
				const lastIndex = state.categories.length
				state.carouselSlideTo = lastIndex
			} else {
				state.carouselSlideTo = index
			}
		},

		setCountedAdsByTag(state, { payload: countedAdsByTag }) {
			state.countedAdsByTag = countedAdsByTag
		},

		setCountedAdsByCategory(state, { payload: countedAdsByCategory }) {
			state.countedAdsByCategory = countedAdsByCategory
		},

		setTagCreationError(state, { payload: errorMessage }) {
			state.tagCreationError = errorMessage
			if (errorMessage) {
				state.tagCreationStatus = enumsLibraries.ERROR
			}
		},
		removeTagCreationError(state) {
			state.tagCreationError = null
		},
		setTagCreationStatus(state, { payload }) {
			state.tagCreationStatus = payload
		},
		setTagAttachingStatus(state, { payload: status }) {
			if ([enumsLibraries.IDLE, enumsLibraries.LOADING].includes(status)) {
				state.tagAttachingStatus = status
			}
		},
		setTagsToDelete(state, { payload: tags }) {
			state.tagsToDelete = tags
		}

	},
})

export const {
	setLoading,
	setFetching,
	setDocument,
	setDocuments,
	updateDocument,
	setDocumentType,
	setCategories,
	setCurrentCategory,
	setTaggingCompanyId,
	setDocumentCategories,
	setTagsToAdd,
	toggleTagsToAdd,
	setTagToCreate,
	clearTagsToCreate,
	removeTagToCreate,
	setTableData,
	clear,
	setCarouselSlideTo,
	setActiveTags,
	setCountedAdsByTag,
	setCountedAdsByCategory,
	setCategoryTag,
	updateCategoryTags,
	setTagCreationError,
	removeTagCreationError,
	setTagCreationStatus,
	setTagAttachingStatus,
	addTagToDocument,
	setTagsToDelete,
	addCategory,
	setCategoryToAdd,
	setCategoryToDelete,
	removeCategory,
	setAttatchingError,
	setDeletingCategoryStatus
} = new_tagManagement.actions

export const Creators = new_tagManagement.actions

export default new_tagManagement.reducer
