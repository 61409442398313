import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

import { reducers } from '.reducers/libraries/library.template'

const initialState = {
	data: [],
	dataBkp: [],
	creating: false,
	created: false,
	loaded: false,
	fetching: false,
	filters: [],
	favorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	actors: [],
	locations: [],
	updating_actor: false,
}

const images = createSlice({
	name: 'LIB/IMAGES',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		updateImage(state, { payload: imagesUpdated }) {
			if (isObject(imagesUpdated)) {
				const foundIndex = state.data.findIndex(
					({ id }) => id === imagesUpdated.id,
				)
				state.data.splice(foundIndex, 1, {
					...imagesUpdated,
					company_id: imagesUpdated?.company_id,
				})
			}
		},

		removeImage(state, { payload: imageId }) {
			state.data = state.data.filter(({ id }) => id !== imageId)
		},

		clearImageBkp(state) {
			state.dataBkp = []
		},

		createImageBkp(state) {
			state.dataBkp = [...state.data]
		},

		restoreImageBkp(state) {
			state.data = [...state.dataBkp]
		},
	},
})

export const Creators = images.actions

export const {
	fetched,
	fetching,
	updateImage,
	setFavorites,
	setAllFavorites,
	clearImageBkp,
	removeImage,
	createImageBkp,
	restoreImageBkp,
} = images.actions

export default images.reducer
