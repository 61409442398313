import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

import { initialStateTemplate, reducers } from '.reducers/libraries/library.template'

const initialState = {
	...initialStateTemplate,
	data: [],
	dataBkp: [],
	creating: false,
	created: false,
	loaded: false,
	fetching: false,
	filters: [],
	favorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	actors: [],
	locations: [],
	updating_actor: false,
	isPlaying: null,
}

const voiceOver = createSlice({
	name: 'LIB/VOICEOVER',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		updateVoiceOver(state, { payload: voiceOverUpdated }) {
			if (isObject(voiceOverUpdated)) {
				const foundIndex = state.data.findIndex(({ id }) => id === voiceOverUpdated.id)
				state.data.splice(foundIndex, 1, {
					...voiceOverUpdated,
				})
			}
		},

		setFavorites(state, { payload: favorites }) {
			state.favorites = favorites
		},

		removeVoiceOver(state, { payload: imageId }) {
			state.data = state.data.filter(({ id }) => id !== imageId)
		},

		clearVoiceOverBkp(state) {
			state.dataBkp = []
		},

		createVoiceOverBkp(state) {
			state.dataBkp = [...state.data]
		},

		restoreVoiceOverBkp(state) {
			state.data = [...state.dataBkp]
		},

		setIsPlaying(state, { payload: isPlaying }) {
			state.isPlaying = isPlaying
		},
	},
})

export const Creators = voiceOver.actions

export const {
	fetched,
	fetching,
	updateVoiceOver,
	setFavorites,
	setAllFavorites,
	clearVoiceOverBkp,
	removeVoiceOver,
	createVoiceOverBkp,
	restoreVoiceOverBkp,
	setIsPlaying,
	clearBkp,
	createAssetsBkp,
	updateAsset,
	restoreBkp,
} = voiceOver.actions

export default voiceOver.reducer
