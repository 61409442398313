import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
}

const new_performanceCategories = createSlice({
	name: 'new_performanceCategories',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.length) {
				state.data = payload
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const Creators = new_performanceCategories.actions

export default new_performanceCategories.reducer
