import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
}

const talentSpecialSkills = createSlice({
	name: 'talentSpecialSkills',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.length) {
				state.data = payload
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const Creators = talentSpecialSkills.actions

export default talentSpecialSkills.reducer
