import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

import removeArrayDupsByKey from '.helpers/removeArrayDupsByKey'

const initialState = {
	loaded: false,
	fetching: false,
	docsType: null,
	documents: [],
	categoriesBySlug: {},
	tagsBySlug: {},
	tagsToCreate: [],
	tagsToRemove: [],
	categories: [],
	attachedTagsByCategoryId: {},
	commonTagsByCategoryId: {},
	tagsByCategoryId: {},
	commonTalentIds: [],
	isProcessingTalents: false,
	attributeFields: [],
}

const globalTagManagement = createSlice({
	name: 'globalTagManagement',
	initialState,
	reducers: {
		setLoading(state, { payload: loading }) {
			state.loading = loading
		},

		setIsProcessingTalents(state, { payload: isProcessingTalents }) {
			state.isProcessingTalents = isProcessingTalents
		},

		setFetching(state, { payload: fetching }) {
			state.fetching = fetching
		},

		setDocsType(state, { payload: library }) {
			state.docsType = library
		},

		setDocuments(state, { payload: documents }) {
			if (Array.isArray(documents)) {
				state.documents = documents
				const justCategories = documents.map(({ categories }) => categories)
				globalTagManagement.caseReducers.setCategoriesBySlug(state, { payload: justCategories })
			}
		},

		removeDocument(state, { payload: documentId }) {
			state.documents = state.documents.filter(({ id }) => id !== documentId)
		},

		updateDocument(state, { payload: document }) {
			state.documents = state.documents.map(doc => {
				if (doc.id === document.id) {
					return document
				}
				return doc
			})
		},


		setCategoriesBySlug(state, { payload: categories = [] }) {
			const categoriesBySlug = categories.reduce((acc, cats) => {
				for (const cat of cats) {
					acc[cat.slug] = acc[cat.slug] ? [...acc[cat.slug], cat.tags] : [cat.tags]
				}
				return acc
			}, {})

			state.categoriesBySlug = categoriesBySlug
		},

		setCommonTalentIds(state, { payload: talentIds }) {
			state.commonTalentIds = talentIds
		},


		setAttachedTagsByCategoryId(state, { payload }) {
			state.attachedTagsByCategoryId = payload
		},

		setCommonTagsByCategoryId(state, { payload }) {
			state.commonTagsByCategoryId = payload
		},

		setTagToCreate(state, { payload: tag }) {
			if (isObject(tag)) {
				state.tagsToCreate.push(tag)
			}
		},

		setTagsToCreate(state, { payload: tags }) {
			state.tagsToCreate = tags
		},

		setTagsToRemove(state, { payload: tags }) {
			state.tagsToRemove = tags
		},


		clearTagsToCreate(state) {
			state.tagsToCreate.splice(0, state.tagsToCreate.length)
		},


		clearTagsToRemove(state) {
			state.tagsToRemove.splice(0, state.tagsToRemove.length)
		},


		updateCategories(state, { payload: category }) {
			state.categories = removeArrayDupsByKey([...state.categories, category], { selector: 'id' })
		},

		setTagsByCategoryId(state, { payload }) {
			if (payload.categoryId) {
				state.tagsByCategoryId[payload.categoryId] = payload?.tags || []
			}
		},


		setAttributeFields(state, { payload: attributeFields }) {
			state.attributeFields = attributeFields
		},


		clear() {
			return { ...initialState }
		}

	},
})


export const reducers = globalTagManagement.actions

export default globalTagManagement.reducer
