import { createSlice } from '@reduxjs/toolkit'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/library.template'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
	favorites: 0,
	allFavorites: 0,
	filters: [],
	currentPage: 1,
	totalOfPages: 0,
	total: 0,
	perPage: 15,
	uploading: false,
	uploaded: false,
	creating: false,
	created: false,
	...initialStateTemplate,
}

const libAds = createSlice({
	name: 'LIB/ADS',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = Number(payload.totalResults) || 0
			}
		},

		filters(state, { payload }) {
			state.filters = payload
		},

		favorites(state, { payload }) {
			state.favorites = payload
		},

		setAdFavorites(state, { payload }) {
			const targetAd = state.data.find((a) => a.id === payload.id)
			targetAd.favorites = payload.favorites
		},

		allFavorites(state, { payload: favorites }) {
			state.allFavorites = favorites
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		uploading(state, { payload }) {
			state.uploading = payload
		},

		clear(state) {
			state.uploading = false
			state.uploaded = false
		},

		uploaded(state) {
			state.uploading = false
			state.uploaded = true
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},
		setDeliveryNumbers(state, { payload }) {
			state.deliveryNumbers = payload
		}
	},
})

export const Creators = libAds.actions

export default libAds.reducer
