function secondsToMMSS(s = 0, showMilliseconds) {
	let time = `00:00`

	try {
		// eslint-disable-next-line no-magic-numbers
		time = new Date(s * 1000).toISOString().substr(14, showMilliseconds ? 9 : 5)
	} catch (e) {
		time = `00:00`
	}

	return time
}

export default secondsToMMSS
