const libraries = {
	ALL: 'all',
	NON_COMPANY: 'non-company',
	READYSET_NAME: 'Ready Set',
	LOADING: 'loading',
	IDLE: 'idle',
	ERROR: 'error',
}

export default libraries
