import { createRoot } from 'react-dom/client'

// components
import App from '.components/App'

// service workers
import * as serviceWorker from './serviceWorker'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import Heap from './helpers/Heap'
import LogRocket from 'logrocket'

// vitals
import reportWebVitals from './reportWebVitals'

import { detect } from 'detect-browser'

if (process.env.REACT_APP_SENTRY_ENABLE === 'true') {
	Sentry.init({
		dsn: `https://${process.env.REACT_APP_SENTRY_ID}@o427223.ingest.sentry.io/${process.env.REACT_APP_SENTRY_PROJECT}`,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0.1,
	})
}

if (process.env.REACT_APP_ENV === 'production') {
	LogRocket.init('s18erj/readyset-pathways')

	LogRocket.getSessionURL((sessionURL) => {
		Sentry.configureScope((scope) => {
			scope.setExtra('sessionURL', sessionURL)
		})
	})

	Heap.initialize(process.env.REACT_APP_HEAP_ID)
}

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App />)

const browser = detect()

// handle the case where we don't detect the browser
if (browser) {
	document.body.classList.add(browser.name)
	reportWebVitals()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV !== 'production') {
	serviceWorker.unregister()
}
