import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selectsByLibId: {},
	fetching: false,
	loaded: false,
	currentPage: 1,
	totalOfPages: 1,
	totalResults: 0,
}

const relatedSelects = createSlice({
	name: 'relatedSelects',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.data && payload.libItemId) {
				state.selectsByLibId[payload.libItemId] = payload.data
				state.fetching = false
				state.loaded = true
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		setCompanyId(state, { payload: companyId }) {
			state.companyId = companyId
		},

		setCurrentPage(state, { payload: currentPage }) {
			state.currentPage = currentPage
		},
	},
})

export const Creators = relatedSelects.actions
export const { setCompanyId, setCurrentPage } = relatedSelects.actions

export default relatedSelects.reducer
