import libMusic from './music.reducer'
import libMusicBulk from './bulk.reducer'
import libMusicCategories from './categories.reducer'
import libMusicCustomCategories from './musicCustomCategories.reducer'
import libMusicFilters from './filters.reducer'
import libMusicSingle from './single.reducer'

const store = {
	libMusic,
	libMusicFilters,
	libMusicBulk,
	libMusicCategories,
	libMusicCustomCategories,
	libMusicSingle,
}

export default store
