/* eslint-disable no-magic-numbers */
import { Spin } from 'antd'

import './styles.sass'

const Loading = function Loading({ size = 50, overlay = false, className }) {
	let sizeString = ''
	if (size < 50) {
		sizeString = 'small'
	} else if (size >= 100) {
		sizeString = 'large'
	} else {
		sizeString = 'default'
	}

	return (
		<div className={`${overlay ? 'loading-overlay' : ''} ${className}`}>
			<Spin size={sizeString} />
		</div>
	)
}

export default Loading
