import PropTypes from 'prop-types'

// components
import Loading from '.components/Loading'

// styles
import S from './styles/waiting-room.module.sass'

function WaitingRoom({ size, height }) {
	return (
		<div className={S.container} style={{ '--containerHeight': height }}>
			<Loading size={size} />
		</div>
	)
}

WaitingRoom.propTypes = {
	size: PropTypes.number,
	height: PropTypes.string,
}

export default WaitingRoom
