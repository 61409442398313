// footages,  ads, libads, selects

export const DocType = {
	FOOTAGE: 'footage',
	FOOTAGES: 'footages',
	ADS: 'ads',
	LIBADS: 'libads',
	SELECTS: 'selects',
	SELECT: 'select',
	TALENT: 'talent',
	TALENTS: 'talents',
	IMAGES: 'images',
	IMAGE: 'image',
	COPY: 'copy',
	COPIES: 'copies',
	MUSIC: 'music',
	MUSICS: 'musics',
	ADSTAGGING: 'adsTagging'
}

export const urlToDocType = {
	footage: DocType.FOOTAGES,
	selects: DocType.SELECTS,
	ads: DocType.LIBADS,
	musics: DocType.MUSICS,
	copy: DocType.COPY,
}

export const annoyingAPIinconsistence = new Map([
	[DocType.SELECTS, 'footage'],
	[DocType.FOOTAGES, 'footage'],
	[DocType.IMAGES, 'image'],
	[DocType.LIBADS, 'lib_ads'],
	[DocType.ADS, 'facebook_ad'],
	[DocType.TALENT, 'talent'],
	[DocType.TALENTS, 'talent'],
	[DocType.COPIES, 'copy'],
	[DocType.MUSICS, 'music'],
	[DocType.ADSTAGGING, 'adsTagging']
])

export default DocType
