import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

const initialState = {
	menuIsOpen: false,
	subMenuHeight: 0,
	fullScreen: false,
	sidebarState: {},
}

const layout = createSlice({
	name: 'LAYOUT',
	initialState,
	reducers: {
		toggleMenu(state, { payload }) {
			state.menuIsOpen = payload.open
			state.subMenuHeight = payload.height
		},
		toggleFullScreen(state, { payload }) {
			state.fullScreen = payload
		},

		setSidebarState(state, { payload: sidebar }) {
			if (isObject(sidebar) && sidebar?.name) {
				state.sidebarState[sidebar.name] = {
					...(state?.sidebarState?.[sidebar.name] || {}),
					open: sidebar?.open,
				}
			}
		},
	},
})

export const Creators = layout.actions

export const { setSidebarState } = layout.actions

export default layout.reducer
