import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

const initialState = {}

const tabsStore = createSlice({
	name: 'UPLOAD.FILE',
	initialState,
	reducers: {
		init(state, { payload: tabsName }) {
			if (tabsName && typeof tabsName === 'string') {
				state[tabsName] = null
			}
		},
		toogle(state, { payload: activeTab }) {
			if (activeTab && isObject(activeTab)) {
				if (activeTab?.active !== state?.[activeTab.tabsName]) {
					state[activeTab.tabsName] = activeTab.active
				}
			}
		},
	},
})

export const { init, toogle } = tabsStore.actions

export default tabsStore.reducer
