import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	creating: false,
	created: false,
	loaded: false,
	fetching: false,
	updating_actor: false,
}

const singleTalent = createSlice({
	name: 'LIB/TALENT/SINGLE',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.id) {
				state.data = payload
				state.loaded = true
				state.fetching = false
				state.updating = false
			}
		},

		fetching(state) {
			state.loaded = false
			state.fetching = true
			state.updating = false
		},

		updating_actor(state) {
			state.updating_actor = true
		},

		updated_actor(state) {
			state.updating_actor = false
		},

		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		creating(state) {
			state.creating = true
		},

		created(state, { payload }) {
			state.creating = false
			state.created = true
			state.data = payload
		},

		clear(state) {
			state.data = false
			state.creating = false
			state.created = false
			state.loaded = false
			state.loading = false
		},
	},
})

export const Creators = singleTalent.actions

export const { fetched, fetching, clear } = singleTalent.actions

export default singleTalent.reducer
