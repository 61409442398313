import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selected: [],
}

const userSelection = createSlice({
	name: 'USER/SELECTION',
	initialState,
	reducers: {
		setSelecteItem(state, { payload: selected }) {
			if (selected) {
				state.selected = [...new Set([...state.selected, selected])]
			}
		},
		setSelected(state, { payload: selected }) {
			if (Array.isArray(selected)) {
				state.selected = selected
			}
		},
		setUnselected(state, { payload: unselected }) {
			if (unselected && state.selected.includes(unselected)) {
				state.selected.splice(state.selected.indexOf(unselected), 1)
			}
		},
	},
})

export const { setSelected, setUnselected, setSelecteItem } =
	userSelection.actions

export default userSelection.reducer
