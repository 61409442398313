import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	hasNextPage: false,
	loaded: false,
	fetching: false,
	page: 1,
}

const new_categoriesReducer = createSlice({
	name: 'new_categoriesReducer',
	initialState,
	reducers: {
		fetched(state, { payload: data }) {
			if (Array.isArray(data)) {
				state.data = data
				state.loaded = true
				state.fetching = false
			}
		},

		fetching(state, { payload: isFetching }) {
			state.fetching = isFetching
		},

		setLoaded(state, { payload: isLoaded }) {
			state.loaded = isLoaded
		},

		setHasNextPage(state, { payload: next }) {
				state.hasNextPage = next		
		},

		setPage(state, { payload: page }) {
			state.page = page
		},

		clear(state) {
			state.data = []
			state.page = 1
			state.hasNextPage = false
		}
	},
})

export const { clear, fetched, fetching, setHasNextPage, setLoaded, setPage } = new_categoriesReducer.actions

export default new_categoriesReducer.reducer
