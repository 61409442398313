import { createSlice } from '@reduxjs/toolkit'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/library.template'

const initialState = {
	...initialStateTemplate,
	data: [],
	bkp: [],
	loaded: false,
	fetching: false,
	filters: [],
	favorites: 0,
	allFavorites: 0,
	currentPage: 1,
	totalOfPages: 0,
	totalResults: 0,
	uploading: false,
	uploaded: false,
	creating: false,
	created: false,
	fetched_actors: false,
	fetched_locations: false,
	fetching_actors: false,
	fetching_locations: false,
	actors: [],
	locations: [],
	updating_actor: false,
	deliveryNumbers: [],
}

const footage = createSlice({
	name: 'LIB/FOOTAGE',
	initialState,
	reducers: {
		...reducers,
		fetched(state, { payload }) {
			if (payload.data) {
				state.data = payload.data
				state.loaded = true
				state.fetching = false
				state.currentPage = payload.page
				state.totalOfPages = Number(payload.totalOfPages) || 0
				state.totalResults = payload.totalResults
			}
		},

		filters(state, { payload }) {
			state.filters = payload
		},

		favorites(state, { payload: favorites }) {
			state.favorites = favorites
		},

		setFootageFavorites(state, { payload }) {
			const targetFootage = state.data.find((f) => f.id === payload.id)
			targetFootage.favorites = payload.favorites
		},

		allFavorites(state, { payload: favorites }) {
			state.allFavorites = favorites
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		fetching_actors(state) {
			state.fetching_actors = true
		},

		fetching_locations(state) {
			state.fetching_locations = true
		},

		updating_actor(state) {
			state.updating_actor = true
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		uploading() {},

		uploadingProgress(state, { payload }) {
			state.uploading[payload.file] = {
				...state.uploading[payload.file],
				progress: payload.progress,
			}
		},

		concludeUpload(state, { payload: fileName }) {
			state.uploading[fileName] = {
				...state.uploading[fileName],
				done: true,
			}
		},

		removeUpload(state, { payload: fileName }) {
			state.uploading[fileName] = undefined
		},

		uploadError(state, { payload }) {
			state.uploading[payload.file] = {
				...state.uploading[payload.file],
				error: payload.error,
			}
		},

		clear(state) {
			state.uploading = {}
			state.uploaded = false
		},

		actors(state, { payload }) {
			state.fetching_actors = false
			state.fetched_actors = true
			if (payload.data) {
				state.actors = payload.data
			}
		},

		locations(state, { payload }) {
			state.fetching_locations = false
			state.fetched_locations = true
			if (payload.data) {
				state.locations = payload.data
			}
		},

		uploaded(state) {
			state.uploading = false
			state.uploaded = true
		},

		setCompanyId(state, { payload: companyId }) {
			state.companyId = companyId
		},

		creating(state, { payload: creating }) {
			state.creating = creating
		},

		created(state) {
			state.creating = false
			state.created = true
		},

		setDeliveryNumbers(state, { payload }) {
			state.deliveryNumbers = payload
		}
	},
})

export const { createAssetsBkp, updateAsset, clearBkp, restoreBkp, setDeliveryNumbers } =
	footage.actions

export const Creators = footage.actions
export const { setCompanyId } = footage.actions

export default footage.reducer
