import { createSlice } from '@reduxjs/toolkit'

// helpers
import { isObject } from '.helpers'

const initialState = {}

const uploadFile = createSlice({
	name: 'UPLOAD.FILE',
	initialState,
	reducers: {
		setUpload(state, { payload: file }) {
			if (isObject({ ...file })) {
				state[file?.name] = {
					progress: 0,
				}
			}
		},

		setUploadProgress(state, { payload: fileProgress }) {
			if (isObject({ ...fileProgress })) {
				if (state[fileProgress?.name]) {
					state[fileProgress?.name].progress = fileProgress?.progress || 0
				}
			}
		},

		removeFile(state, { payload: file }) {
			if (isObject({ ...file })) {
				state[file?.name] = null
			}
		},
	},
})

export const { setUpload, setUploadProgress, removeFile } = uploadFile.actions

export default uploadFile.reducer
