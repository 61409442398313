import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	loaded: false,
	fetching: false,
	updating_actor: false,
	keyboard_blocked: false,
	isClientProvided: false,
	actorCategories: [],
}

const singleFootage = createSlice({
	name: 'singleFootage',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			if (payload.id) {
				state.data = payload
				state.loaded = true
				state.fetching = false
				state.updating = false
			}
		},

		fetching(state) {
			state.loaded = false
			state.fetching = true
			state.updating = false
		},

		updateActors(state, { payload: talents }) {
			if (Array.isArray(talents)) {
				state.data.talents = talents
			}
		},

		updateCategories(state, { payload: categories }) {
			state.data.categories = categories
		},

		updating_actor(state) {
			state.updating_actor = true
		},

		updated_actor(state) {
			state.updating_actor = false
		},

		updateAttribute(state, { payload: property }) {
			if (property.attribute) {
				state.data[property.attribute] = property.value
			}
		},

		updateTags(state, { payload: tags }) {
			state.data.tags = tags
		},

		blockKeyboard(state) {
			state.keyboard_blocked = true
		},

		unlockKeyboard(state) {
			state.keyboard_blocked = false
		},

		updating(state, { payload }) {
			state.updating = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},

		setIsClientProvided(state, { payload: isClientProvided }) {
			if (state?.data?.id) {
				state.data.client_provided = isClientProvided
			}
		},

		setCompanyId(state, { payload: companyId }) {
			state.bkpCompanyId = state.data.company_id
			state.data.company_id = companyId
		},

		resetCompanyId(state) {
			state.data.company_id = state.bkpCompanyId
		},

		setIsBranded(state) {
			if (state?.data?.id) {
				state.data.branded = !state.data?.branded
			}
		},

		setBusinessUnit(state, { payload: businessUnit }) {
			if (state?.data?.id) {
				state.data.business_unit = businessUnit
			}
		},

		setActorCategories(state, { payload }) {
			state.actorCategories = payload
		},

		clear(state) {
			state.data = false
			state.loaded = false
			state.loading = false
		},
	},
})

export const Creators = singleFootage.actions

export const { fetched, fetching, clear, setIsClientProvided, setCompanyId, setIsBranded, updateTags } =
	singleFootage.actions

export default singleFootage.reducer
