import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: [],
	loaded: false,
	fetching: false,
	lastAdded: false,
}

const accountCategories = createSlice({
	name: 'accountCategories',
	initialState,
	reducers: {
		fetched(state, { payload }) {
			state.data = payload
			state.loaded = true
			state.fetching = false
		},

		added(state) {
			state.lastAdded = Date.now()
		},

		fetching(state, { payload }) {
			state.fetching = payload
		},

		loaded(state, { payload }) {
			state.loaded = payload
		},
	},
})

export const Creators = accountCategories.actions

export default accountCategories.reducer
