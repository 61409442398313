import { createSlice } from '@reduxjs/toolkit'

export const Types = {
	LOGOUT: 'AUTH/logout',
	SWITCH_ACCOUNT: 'AUTH/switchAccount',
}

const defaultUser = {
	uid: null,
	avatar_url: null,
	display_name: null,
	email: null,
	first_name: null,
	last_name: null,
	username: null,
}

const initialState = {
	isAuthenticated: false,
	fetching: false,
	resolved: false,
	error: null,
	user: {
		...defaultUser,
	},
	currentCompany: null,
	initialCompany: null,
	fetchingGoogle: null,
	successMessage: null,
}

const auth = createSlice({
	name: 'AUTH',
	initialState,
	reducers: {
		login(state, { payload }) {
			if (payload) {
				state.isAuthenticated = true
				state.fetching = false
				state.user = payload
				state.resolved = true
				state.fetchingGoogle = false
			}
		},

		logout(state) {
			state.isAuthenticated = false
			state.fetching = false
			state.user = defaultUser
		},

		fetching(state, { payload: fetching }) {
			if (fetching) {
				state.fetching = fetching
				state.error = null
			}
		},

		fetchingGoogle(state, { payload }) {
			if (payload) {
				state.fetchingGoogle = payload
				state.error = null
			}
		},

		loginError(state, { payload: error }) {
			if (error) {
				state.isAuthenticated = false
				state.fetchingGoogle = false
				state.fetching = false
				state.user = defaultUser
				state.error = error
			}
		},

		setResolved(state, { payload: resolved }) {
			state.resolved = resolved
		},

		setAuthState(state, { payload }) {
			state.isAuthenticated = payload
		},

		updateUser(state, { payload }) {
			state.user = payload
		},

		forgotPasswordError(state, { payload: error }) {
			state.error = error
			state.fetching = false
		},

		forgotPasswordSuccessMessage(state, { payload: message }) {
			state.error = null
			state.successMessage = message
			state.fetching = false
		},
	},
})

export const Creators = {
	...auth.actions,
}

export const { updateUser } = auth.actions

export default auth.reducer
