import { applyMiddleware, compose, createStore } from 'redux'
import reducers from './reducers'
import thunks from 'redux-thunk'

const devMode = process.env.NODE_ENV !== 'production'

const composeEnhancers =
	devMode && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				trace: true, // (action) => { return ‘trace as string’; }
				traceLimit: 25,
		  }) || compose
		: compose

export default createStore(reducers, composeEnhancers(applyMiddleware(thunks)))

export function store(wes) {
	return wes
}
