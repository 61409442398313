import { combineReducers } from '@reduxjs/toolkit'

// global
import globalStores from './global'
import layoutStore from './global/layout.reducer'
import toastStore from './toast/reducer'

import categories from './performance/ads/categories.reducer'
import customCategories from './performance/ads/customCategories.reducer'

// import singleAd from './singleAd.reducer'

import accountCategories from './accountCategories.reducer'

import files from './files.reducer'

import new_performanceCategories from './performance/ads/v2/categories.reducer'

import auth, { Types } from './auth/auth.reducer'

import relatedSelects from './relatedSelects.reducer'
import users from './users.reducer'

// libraries
import footage from '../../views/Libraries/Footage/store/footage.reducer'
import footageBulk from '../../views/Libraries/Footage/store/bulk.reducer'
import footageCategories from '../../views/Libraries/Footage/store/categories.reducer'
import footageCustomCategories from '../../views/Libraries/Footage/store/customCategories.reducer'
import footageFilters from '../../views/Libraries/Footage/store/filters.reducer'
import singleFootage from '../../views/Libraries/Footage/store/single.reducer'

import selects from './libSelects/selects.reducer'
import selectsBulk from './libSelects/selectsBulk.reducer'
import selectsFilters from './libSelects/selectsFilters.reducer'
import singleSelect from './libSelects/single.reducer'

import libAds from './libAds/libAds.reducer'
import libAdsBulk from './libAds/libAdsBulk.reducer'
import libAdsCustomCategories from './libAds/libAdsCustomCategories.reducer'
import libAdsFilters from './libAds/filters.reducer'
import libAdsSingle from './libAds/single.reducer'

import libTalent from './libTalent'
import talentCategories from './libTalent/talentCategories.reducer'
import talentComments from './libTalent/talentComments.reducer'
import talentSpecialSkills from './libTalent/talentSpecialSkills.reducer'

import libImages from './libImages'
import libMusic from './libMusic'

import libCopy from './libCopy'

import libVoiceOver from './libVoiceOver'

import highResolutionUploads from './highResolutionUploads/highResolutionUploads.reducer'

import librariesStore from './libraries/geral.reducer'

import new_tagManagement from './tagManagement/v2/reducer'
import tabsStore from './tabs/reducer'
import tagManagement from './tagManagement/reducer'

import uploadFileStore from './uploadFile/reducer'

import copySingle from './libCopy/copySingle.reducer'

import keywords from './keywords/keywords.reducer'

import userSelection from './global/userSelection.reducer'

import librariesSingleNavigation from './libraries/singleNavigation.reducer'
import links from './linkManager/links.reducer'
import singleLink from './linkManager/singleLink.reducer'

import mostSelectedTags from './mostSelectedTags.reducer'

import renamerTool from './renamerTool/renamerTool.reducer'

// Categories V2
import new_categoriesReducer from './libraries/v2/categories.reducer'
import new_tagsReducer from './libraries/v2/tags.reducer'

import globalTagManagement from './globalTagManagement/reducer'

const appReducer = combineReducers({
	// singleAd,
	auth,
	categories,
	customCategories,
	accountCategories,
	files,
	tagManagement,
	new_tagManagement,
	globalTagManagement,
	layoutStore,

	footage,
	singleFootage,
	footageCategories,
	footageCustomCategories,
	footageFilters,
	footageBulk,
	highResolutionUploads,
	libAds,
	libAdsFilters,
	libAdsSingle,
	libAdsCustomCategories,
	libAdsBulk,
	...libImages,
	talentCategories,
	talentComments,
	talentSpecialSkills,
	librariesStore,
	relatedSelects,
	toastStore,
	singleSelect,
	selects,
	selectsFilters,
	selectsBulk,
	users,
	tabsStore,

	uploadFileStore,
	userSelection,
	...globalStores,
	...libTalent,
	links,
	singleLink,
	copySingle,
	...libMusic,
	...libCopy,
	...libVoiceOver,
	librariesSingleNavigation,
	keywords,
	mostSelectedTags,
	renamerTool,
	new_categoriesReducer,
	new_tagsReducer,
	new_performanceCategories,
})

const handleEdgeCases = {
	// TODO check if this is still needed
	[Types.SWITCH_ACCOUNT]: (state) => ({
		auth: state.auth,
		layoutStore: state.layoutStore,
		detailsTable: state.detailsTable,
		detailsTableColumns: state.detailsTableColumns,
		performanceFilters: state.performanceFilters,
		selectsFilters: state.selectsFilters,
		footageFilters: state.footageFilters,
	}),
	// this clear all stores.
	[Types.LOGOUT]: () => undefined,
}

function reducer(state, action) {
	const newState = handleEdgeCases[action.type]?.(state) || state
	return appReducer(newState, action)
}

export default reducer
